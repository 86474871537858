import { Suspense } from "react"

import { Outlet } from "react-router"

import { Chatbot } from "~/components/Chatbot"

export function BaseLayout() {
  return (
    <Suspense fallback={<div className="min-h-[80%] w-full" />}>
      <Chatbot />
      <Outlet />
    </Suspense>
  )
}
