import type { Brands } from "./Brands"
import { gammaFrBeRedirects, gammaNlBeRedirects } from "./routes/gammaBeRedirects"
import { gammaNlRedirects } from "./routes/gammaNlRedirects"
import { karweiNlRedirects } from "./routes/karweiNlRedirects"

export function getRedirectsConfig(brand: Brands) {
  switch (brand) {
    case "gamma_nl":
      return {
        nl: gammaNlRedirects,
      }
    case "gamma_be":
      return {
        nl: gammaNlBeRedirects,
        fr: gammaFrBeRedirects,
      }
    case "karwei_nl":
      return {
        nl: karweiNlRedirects,
      }
    default:
      return {
        nl: gammaNlRedirects,
      }
  }
}
