import { createHttpClient } from "@intergamma/account"

import { mapOrdersOverviewResponse } from "./mappers/mapOrdersOverviewResponse"
import type { CancelOrderEntryPayload } from "./types/CancelOrderEntry"
import type { OrderOverview, OrderOverviewResponse } from "./types/OrderOverview"

export function createOrdersService(MYACCOUNT_URL: string) {
  const baseClient = createHttpClient(`${MYACCOUNT_URL}/api/orders`, {
    withCredentials: true,
  })

  return {
    getOrders(): Promise<OrderOverview[]> {
      return baseClient.get<OrderOverviewResponse[]>("/").then((orders) => mapOrdersOverviewResponse(orders))
    },

    cancelOrderEntry(payload: CancelOrderEntryPayload): Promise<void> {
      return baseClient.post<void>(`${payload.orderId}/cancelEntry`, {
        product: {
          code: payload.productCode,
          name: "",
          url: "",
          imageUrl: "",
        },
        quantity: payload.quantity,
      })
    },
  }
}
