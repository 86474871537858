import { lazy } from "react"

import { useTranslation } from "react-i18next"
import type { RouteObject } from "react-router"
import { Navigate, useSearchParams } from "react-router"

import { getLanguage } from "~/helpers/getLanguage"

import { useMyAccountConfig } from "~/config/MyAccountConfigContext"

const VerificationTranslationRedirect = lazy(() => import("~/pages/VerificationTranslationRedirect"))

export function useRedirectRoutes(): RouteObject[] {
  const { t, i18n } = useTranslation("urls")
  const language = getLanguage(i18n.language)
  const [searchParams] = useSearchParams()
  const searchParamsString = [...searchParams.values()].length > 0 ? `?${searchParams.toString()}` : ""

  const { redirects } = useMyAccountConfig()
  const currentRedirects = redirects[language]

  if (!currentRedirects) {
    return []
  }

  const configRedirects = Object.entries(currentRedirects).map(([path, location]) => ({
    path,
    element: <Navigate replace to={`${location}${searchParamsString}`} />,
  }))

  const translationRedirects: RouteObject[] = [
    {
      path: `${t("/verification")}/upgrade-customer`,
      element: <Navigate replace to={`${t("/verification")}/upgrade${searchParamsString}`} />,
    },
    {
      path: "/my/verification/:type",
      element: <VerificationTranslationRedirect />,
    },
    {
      path: "/my/verify/:type",
      element: <VerificationTranslationRedirect />,
    },
  ]

  return [...configRedirects, ...translationRedirects]
}
