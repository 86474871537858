import { forwardRef, type SVGProps } from "react";

export interface PackageIconProps extends SVGProps<SVGSVGElement> {}

export const Package = forwardRef<SVGSVGElement, PackageIconProps>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.609 2.61674C11.6087 2.61687 11.6092 2.61661 11.609 2.61674L9.12193 3.85083L16.7902 7.65587L19.7786 6.17301L12.6117 2.61674C12.6114 2.61662 12.6119 2.61687 12.6117 2.61674C12.4564 2.5403 12.2842 2.5 12.1103 2.5C11.9364 2.5 11.7643 2.5403 11.609 2.61674ZM20.7206 7.38011L17.1414 9.15613C17.1301 9.1621 17.1187 9.16778 17.1071 9.17316L12.8603 11.2804L12.8604 20.7575L20.0983 17.1661L20.0999 17.1653C20.2873 17.0729 20.4442 16.9308 20.5535 16.7558C20.6628 16.5809 20.7205 16.3797 20.7206 16.1747V7.38011ZM11.3604 20.7622L11.3603 11.2804L3.50005 7.38012V16.1711C3.49861 16.3764 3.55495 16.5784 3.66329 16.7543C3.77149 16.93 3.92742 17.073 4.11417 17.1666C4.11448 17.1668 4.1148 17.1669 4.11511 17.1671L11.3604 20.7622ZM4.44201 6.17301L7.4346 4.68808L15.1029 8.49312L12.1103 9.97805L4.44201 6.17301ZM7.02889 3.21489L3.45732 4.98711C3.45702 4.98726 3.45672 4.98741 3.45642 4.98756C3.02039 5.20279 2.65264 5.53479 2.39508 5.94699C2.1373 6.35953 2.00031 6.83578 2.00005 7.32213L2.00005 16.1634C1.99717 16.6497 2.13101 17.1267 2.38604 17.5408C2.64152 17.9557 3.00826 18.2907 3.44424 18.5087L3.44631 18.5098L10.9343 22.2253C11.2982 22.406 11.6993 22.5 12.1056 22.5C12.512 22.5 12.9131 22.406 13.277 22.2253L20.7633 18.5106C20.7632 18.5106 20.7635 18.5105 20.7633 18.5106C21.1996 18.2953 21.5679 17.963 21.8256 17.5507C22.0834 17.1382 22.2204 16.6619 22.2206 16.1755V7.32254C22.2204 6.83618 22.0834 6.35953 21.8256 5.94699C21.568 5.53481 21.2003 5.20283 20.7643 4.9876C20.764 4.98744 20.7637 4.98727 20.7633 4.98711L13.2768 1.27225L13.2751 1.27143C12.9129 1.09282 12.5143 1 12.1103 1C11.7064 1 11.3078 1.09282 10.9456 1.27143L10.9439 1.27225L7.16729 3.14621C7.11907 3.16425 7.07266 3.18723 7.02889 3.21489Z"
        fill="currentColor"
      />
    </svg>
  ),
);

(Package as React.FC).displayName = "Package";
