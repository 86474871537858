import { useEffect } from "react"

import { useQueryClient } from "@tanstack/react-query"
import { useErrorHandler } from "react-error-boundary"

interface RegisterQueryClientErrorHandlerProps {
  children: JSX.Element
}

export function RegisterQueryClientErrorHandler({ children }: RegisterQueryClientErrorHandlerProps) {
  const queryClient = useQueryClient()
  const handleError = useErrorHandler()

  useEffect(() => {
    const defaultOptions = queryClient.getDefaultOptions()

    queryClient.setDefaultOptions({
      queries: { ...defaultOptions.queries },
      mutations: { ...defaultOptions.mutations },
    })
  }, [queryClient, handleError])

  return children
}
