import { useCallback } from "react"

import { datadogRum } from "@datadog/browser-rum"

import type { VerifyOptions } from "~/features/verify/types/VerifyOptions"

export function useRumEvent() {
  const loginEvent = useCallback((location: "loginPage" | "loginHeader") => {
    datadogRum.addAction("login", {
      location,
    })
  }, [])

  const signupEvent = useCallback(() => {
    datadogRum.addAction("signup")
  }, [])

  const logoutEvent = useCallback(() => {
    datadogRum.addAction("logout")
  }, [])

  const generateLoyaltyCardEvent = useCallback(() => {
    datadogRum.addAction("generate_loyalty_card")
  }, [])

  const setPreferredLoyaltyCardEvent = useCallback(() => {
    datadogRum.addAction("set_preferred_loyalty_card")
  }, [])

  const removeLoyaltyCardEvent = useCallback(() => {
    datadogRum.addAction("loyalty_card_removal")
  }, [])

  const claimedLoyaltyPointsEvent = useCallback(() => {
    datadogRum.addAction("claimed_loyalty_points")
  }, [])

  const claimedLoyaltyPointsFailedEvent = useCallback((reason: string) => {
    datadogRum.addAction("claimed_loyalty_points_failed", {
      reason,
    })
  }, [])

  const requestedNewPasswordEvent = useCallback(() => {
    datadogRum.addAction("request_new_password")
  }, [])

  const requestedNewPasswordFailedEvent = useCallback((errorMessage: string) => {
    datadogRum.addAction("request_new_password_failed", {
      reason: errorMessage,
    })
  }, [])

  const verifiedEvent = useCallback((type: VerifyOptions) => {
    datadogRum.addAction("verified", {
      type,
    })
  }, [])

  const verifiedFailedEvent = useCallback((error: Error | null) => {
    datadogRum.addAction("verified_failed", {
      errorDetails: error?.message,
    })
  }, [])

  const verifiedPhoneNumberEvent = useCallback(() => {
    datadogRum.addAction("verified_phonenumber")
  }, [])

  const verifiedPhoneNumberFailedEvent = useCallback((error: Error | null) => {
    datadogRum.addAction("verified_phonenumber_failed", {
      errorDetails: error?.message,
    })
  }, [])

  const registerByLoyaltyCardEvent = useCallback(() => {
    datadogRum.addAction("register_by_loyalty_card")
  }, [])

  const registerByLoyaltyCardErrorEvent = useCallback((error: Error | null) => {
    datadogRum.addAction("register_by_loyalty_card_error", {
      errorDetails: error?.message,
    })
  }, [])

  const activateRegisterByLoyaltyCardEvent = useCallback(() => {
    datadogRum.addAction("activate_register_by_loyalty_card")
  }, [])

  const activeRegisterByLoyaltyCardErrorEvent = useCallback((error: Error | null) => {
    datadogRum.addAction("activate_register_by_loyalty_card_error", {
      errorDetails: error?.message,
    })
  }, [])

  const verifyRegistrationEvent = useCallback((location: string) => {
    datadogRum.addAction("verify_registration", {
      location,
    })
  }, [])

  const verifyRegistrationErrorEvent = useCallback((error: Error | null) => {
    datadogRum.addAction("verify_registration_error", {
      errorDetails: error?.message,
    })
  }, [])

  const resendVerificationEvent = useCallback(() => {
    datadogRum.addAction("resend_verification")
  }, [])

  const resendVerificationErrorEvent = useCallback((error: Error | null) => {
    datadogRum.addAction("resend_verification_error", {
      errorDetails: error?.message,
    })
  }, [])

  const purchaseDetailsErrorEvent = useCallback((error: Error | null) => {
    datadogRum.addAction("purchase_details_error", {
      errorDetails: error?.message,
    })
  }, [])

  const purchaseStatusErrorEvent = useCallback((error: Error | null) => {
    datadogRum.addAction("purchase_status_error", {
      errorDetails: error?.message,
    })
  }, [])

  return {
    loginEvent,
    signupEvent,
    logoutEvent,
    generateLoyaltyCardEvent,
    setPreferredLoyaltyCardEvent,
    removeLoyaltyCardEvent,
    claimedLoyaltyPointsEvent,
    claimedLoyaltyPointsFailedEvent,
    requestedNewPasswordEvent,
    requestedNewPasswordFailedEvent,
    verifiedEvent,
    verifiedFailedEvent,
    verifiedPhoneNumberEvent,
    verifiedPhoneNumberFailedEvent,
    registerByLoyaltyCardEvent,
    registerByLoyaltyCardErrorEvent,
    activateRegisterByLoyaltyCardEvent,
    activeRegisterByLoyaltyCardErrorEvent,
    verifyRegistrationEvent,
    verifyRegistrationErrorEvent,
    resendVerificationEvent,
    resendVerificationErrorEvent,
    purchaseDetailsErrorEvent,
    purchaseStatusErrorEvent,
  }
}
