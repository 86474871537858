import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { useProfileService } from "~/features/shared/ServicesContext"

import { isAxiosError } from "~/helpers/isAxiosError"
import { log } from "~/helpers/tracking/datadog-logging"
import { TranslatedError } from "~/helpers/TranslatedError"

import type { EmailUpdateRequest } from "~/services/base-api/generated"

export function useUpdateEmail() {
  const profileService = useProfileService()

  const { t } = useTranslation(["change-email", "errors"])

  function updateEmail(body: EmailUpdateRequest) {
    return profileService.updateEmail(body).catch((error) => {
      if (isAxiosError(error)) {
        const statusCode = error.response?.status

        if (statusCode === 400) {
          throw new TranslatedError(t("change-email:emailsEqualError"))
        }

        if (statusCode === 403) {
          throw new TranslatedError(t("change-email:currentEmailRequired"))
        }
        if (statusCode === 409) {
          throw new TranslatedError(t("change-email:errorExistingEmailMessage"))
        }
      }

      log.error("Updating email failed", {}, error)

      throw new TranslatedError(`${t("change-email:errorMessage")} ${t("errors:ContactCustomerSupport")}`)
    })
  }

  return useMutation({
    mutationFn: updateEmail,
    throwOnError: false,
  })
}
