/**
 * Get a two letter language code from the i18n language code. Always default to "nl" because karwei would result in language "ka".
 * @param i18nLanguage language coming from i18n
 * @returns "nl" or "fr"
 */
export const getLanguage = (i18nLanguage: string): "nl" | "fr" => {
  const language = i18nLanguage.substring(0, 2)

  if (language === "fr") {
    return "fr"
  }

  return "nl"
}
