import type { NonIndexRouteObject } from "react-router"

export const routeMapper = (country: "nl" | "be", route: NonIndexRouteObject) => {
  if (country !== "be") {
    return route
  }

  return {
    ...route,
    path: route?.path?.substring(7),
  }
}
