/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */

import { datadogLogs, HandlerType, StatusType } from "@datadog/browser-logs"

import { DeviceType } from "../getDeviceType"

enum RequestMode {
  App = "APP_MODE",
  Browser = "DEFAULT_MODE",
  Kiosk = "KIOSK_MODE",
}

export function addRequestModeToLogContext(deviceType: DeviceType) {
  try {
    const requestMode = deviceTypeToRequestMode(deviceType)

    datadogLogs.setGlobalContextProperty("requestMode", requestMode)
  } catch (error) {
    // just output it to the console only
    // we don't want DataDog logging to disturb the user experience
    console.error(`Failed to add the requestMode to the global DataDog log context: ${error}`)
  }
}

interface InitDataDogProps {
  brand: string
  skin: string
  version: string
  clientKey: string
  env: string
  serviceName: string
  logDestination: HandlerType
  logLevel: StatusType
  preferredStore: string | undefined
}

export function initDataDog({
  brand,
  skin,
  version,
  clientKey,
  env,
  serviceName,
  logDestination,
  logLevel,
  preferredStore,
}: InitDataDogProps) {
  const defaultLogContext = {
    brand,
    formula: skin,
    preferredStore,
    version,
    module: "myaccount",
  }

  datadogLogs.init({
    beforeSend: (event) => {
      // We don't send out known errors
      if (
        event.error?.stack?.includes("TranslatedError") ||
        event.error?.stack?.includes("AxiosError") ||
        event.error?.stack?.includes("csp_violation")
      ) {
        return false
      }

      if (event.http && event.http.status_code === 0) {
        // request aborted. This is done either intentionally by us or due
        // to the user refreshing/navigating. We don't consider these as an error
        // that we should do something about
        return false
      }

      // remove secured data from URL as it could lead to PII data
      event.view.url = event.view.url.replace(/hash=[^&]*/, "hash=REDACTED")
      event.view.url = event.view.url.replace(/signature=[^&]*/, "signature=REDACTED")
      event.view.url = event.view.url.replace(/email=[^&]*/, "email=REDACTED")
      event.view.url = event.view.url.replace(/postalCode=[^&]*/, "postalCode=REDACTED")

      return true
    },
    clientToken: clientKey,
    env,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: serviceName,
    site: "datadoghq.eu",
    trackSessionAcrossSubdomains: true,
    version,
  })

  Object.entries(defaultLogContext).forEach(([key, value]) => {
    // we explicitly add instead of set to prevent re-imports
    // from overwriting the entire context
    datadogLogs.setGlobalContextProperty(key, value)
  })

  function getLogDestination(): HandlerType {
    if (logDestination && HandlerType.hasOwnProperty(logDestination)) {
      return logDestination as HandlerType
    }

    console.warn("Failed to read DataDog log destination config. Defaulting to: http")

    return HandlerType.http
  }

  function getLogLevel(): StatusType {
    if (logLevel && StatusType.hasOwnProperty(logLevel)) {
      return logLevel as StatusType
    }

    console.warn("Failed to read DataDog log level config. Defaulting to: info")

    return StatusType.info
  }

  datadogLogs.logger.setLevel(getLogLevel())
  datadogLogs.logger.setHandler(getLogDestination())
  datadogLogs.setGlobalContext(defaultLogContext)
}

export const log = datadogLogs.logger

function deviceTypeToRequestMode(deviceType: DeviceType): RequestMode {
  switch (deviceType) {
    case DeviceType.App:
      return RequestMode.App
    case DeviceType.Browser:
      return RequestMode.Browser
    case DeviceType.Kiosk:
      return RequestMode.Kiosk
    default:
      throw new Error(`A device type was provided that is not known as a request mode: ${deviceType}`)
  }
}

export { datadogLogs as datadog }
