import type {
  LoyaltyDashboardOverview,
  LoyaltyDashboardOverviewResponse,
  LoyaltyPointMutation,
  LoyaltyPointMutationResponse,
} from "../types/LoyaltyPoints"

import { mapMoneyResponse } from "~/services/mappers/mapMoneyResponse"

export function mapLoyaltyDashboardOverviewResponse(
  response: LoyaltyDashboardOverviewResponse
): LoyaltyDashboardOverview {
  return {
    ...response,
    moneyBalance: mapMoneyResponse(response.moneyBalance),
    mutations: response.mutations.map((mutation) => mapLoyaltyMutationResponse(mutation)),
  }
}

function mapLoyaltyMutationResponse(mutationResponse: LoyaltyPointMutationResponse): LoyaltyPointMutation {
  return {
    ...mutationResponse,
    timestamp: new Date(mutationResponse.timestamp),
  }
}
