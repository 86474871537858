import { useEffect, useState } from "react"

import { useVerificationCodeForm } from "@intergamma/account"
import { Button } from "@intergamma/ui/button"

import { CODE_LENGTH, CodeField } from "@intergamma/ui/form"
import { useTranslation } from "react-i18next"

type VerifyChangeProps = {
  isLoading: boolean
  isSuccess: boolean
  verificationError: string | null
  onSuccess: () => void
  onSubmit: (code: string) => void
  onResend?: () => void
}

export function VerifyChange({
  isLoading,
  isSuccess,
  verificationError,
  onSuccess,
  onSubmit,
  onResend,
}: VerifyChangeProps) {
  const { t } = useTranslation(["register", "errors", "ig-form", "urls", "login-form"])

  const [error, setError] = useState<string | null>(null)

  const form = useVerificationCodeForm()

  const {
    register,
    setValue,
    setFocus,
    resetField,
    watch,
    formState: { errors },
  } = form

  const [disableResend, setDisableResend] = useState(false)

  useEffect(() => {
    if (verificationError) {
      setError(verificationError)
      setValue("code", "")
      setFocus("code")
    }
  }, [verificationError, setValue, setFocus])

  const handleSubmit = form.handleSubmit((values) => {
    setError(null)
    onSubmit(values.code)
  })

  const sendAgain = () => {
    if (onResend) {
      resetField("code")
      setFocus("code")
      setError(null)
      onResend()
      // Disable the resend button for 30 seconds so people don't spam it.
      setDisableResend(true)
      setTimeout(() => {
        setDisableResend(false)
      }, 30000)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      onSuccess()
    }
  }, [isSuccess, onSuccess])

  const codeValue = watch("code")

  useEffect(() => {
    if (codeValue?.length === CODE_LENGTH) {
      handleSubmit()
    }
  }, [codeValue]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      method="POST"
      className="mt-4 grid grid-cols-1 gap-4"
      data-testid="verify-form"
    >
      <div className="col-span-1">
        <div className="my-1 flex items-end justify-center gap-2">
          <div className="block w-full text-75">
            <CodeField
              {...register("code")}
              label={t("login-form:verifyNewPasswordCodeLabel", { length: CODE_LENGTH })}
              error={errors.code?.message || error}
              description={t("login-form:verifyNewPasswordCodeDescription")}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
      <div className="col-span-1">
        <Button loading={isLoading} className="w-full">
          {isLoading ? t("register:isVerifying") : t("register:confirm")}
        </Button>
      </div>
      {onResend && (
        <div className="col-span-1">
          <button
            className="h-auto cursor-pointer border-none p-0 underline"
            disabled={disableResend}
            onClick={sendAgain}
            type="button"
          >
            {t("register:buttonLabelResendCode")}
          </button>
        </div>
      )}
    </form>
  )
}
