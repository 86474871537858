import { useSuspenseQuery } from "@tanstack/react-query"

import { useProfileService } from "~/features/shared/ServicesContext"

export const QUERY_KEY = "profile"

export const useGetPersonalInformation = () => {
  const profileService = useProfileService()

  return useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: profileService.getProfile,
  })
}
