import { isMobileApp } from "@intergamma/common/device"
import { dispatchMobileAppEvent } from "@intergamma/mobile-app"
import { Button } from "@intergamma/ui/button"
import { CheckboxField } from "@intergamma/ui/form"
import { ExclamationMark } from "@intergamma/ui/icons/ExclamationMark"
import { Feedback } from "@intergamma/ui/icons/Feedback"
import { Snackbar } from "@intergamma/ui/snackbar"

import { zodResolver } from "@hookform/resolvers/zod"
import { Controller, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { z } from "zod"

import { getLanguage } from "~/helpers/getLanguage"

import { useMyAccountConfig } from "~/config/MyAccountConfigContext"

interface RemoveAccountFormProps {
  onSubmit: () => void
  onClose: () => void
  errorMessage: null | string
  isLoading: boolean
}

export function RemoveAccountForm({ errorMessage, isLoading, onSubmit, onClose }: RemoveAccountFormProps) {
  const { t, i18n } = useTranslation(["profile", "urls", "common"])
  const {
    urls,
    domainConfig,
    baseConfig: { country },
  } = useMyAccountConfig()

  const form = useForm({
    defaultValues: {
      confirmation: false,
    },
    resolver: zodResolver(z.object({ confirmation: z.literal(true) })),
  })

  const {
    control,
    watch,
    formState: { errors },
  } = form

  const confirmation = watch("confirmation")

  const submit = form.handleSubmit((values) => {
    if (values.confirmation) {
      onSubmit()
    }
  })

  const openChat = () => {
    if (isMobileApp) {
      dispatchMobileAppEvent({
        app: "myaccount",
        type: "IG_OPEN_CHAT",
      })
    } else {
      window.fcWidget?.open()
    }
  }

  if (errorMessage === "424") {
    const language = getLanguage(i18n.language) === "fr" ? "fr" : "nl"
    const currentUrls = urls[language]!

    return (
      <div className="flex flex-col gap-4">
        <p>
          <Trans
            t={t}
            ns="profile"
            i18nKey="bodyAccountRemovalNotPossible"
            defaultValue="We kunnen je account op dit moment niet verwijderen in verband met een openstaande bestelling of verhuurcontract.
Neem hierover indien nodig contact op met je GAMMA bouwmarkt of de <cs-link>klantenservice</cs-link>."
            components={{
              "cs-link": (
                <a href={currentUrls.customerService} className="underline">
                  klantenservice
                </a>
              ),
            }}
          />
        </p>
        <p>
          <Trans
            t={t}
            ns="profile"
            i18nKey="contactUs"
            defaultValue="Bel ons: <phone-link>{{phoneNumber}}</phone-link>, of chat met ons en krijg direct antwoord."
            components={{
              "phone-link": (
                <a href={domainConfig.phoneNumberCustomerService.forHref} className="underline">
                  {domainConfig.phoneNumberCustomerService.forDisplay}
                </a>
              ),
            }}
            values={{
              phoneNumber: domainConfig.phoneNumberCustomerService.forDisplay,
            }}
          />
        </p>
        {country === "nl" && (
          <div className="flex flex-row gamma:text-ignew-functional-primary-400 karwei:text-ignew-functional-primary-600">
            <button type="button" className="flex gap-2" onClick={openChat}>
              <Feedback /> {t("common:openChat")}
            </button>
          </div>
        )}
        <div className="flex flex-row justify-end">
          <Button type="button" onClick={onClose}>
            {t("common:buttonClose")}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <form className="flex flex-col gap-4" onSubmit={submit}>
      <p>{t("profile:bodyRemoveAccount")}</p>
      <ul>
        {t("profile:removalReminders", { returnObjects: true }).map((reminder) => (
          <li key={reminder} className="ml-6 list-disc pl-2">
            {reminder}
          </li>
        ))}
      </ul>
      <Controller
        name="confirmation"
        control={control}
        render={({ field }) => (
          <CheckboxField
            ref={field.ref}
            name={field.name}
            checked={field.value}
            defaultChecked={false}
            label={t("profile:removeAccountCheckLabel")}
            onCheckedChange={(checked) => {
              if (checked === "indeterminate") {
                return
              }

              field.onChange(checked)
            }}
          />
        )}
      />
      {errors.confirmation && !confirmation && (
        <Snackbar variant="error" icon={<ExclamationMark />}>
          {t("profile:errorNoCheckboxRemoveAccount")}
        </Snackbar>
      )}
      <div className="flex flex-row justify-end gap-4">
        <Button type="button" variant="secondary" onClick={onClose}>
          {t("common:cancel")}
        </Button>
        <Button type="submit" loading={isLoading}>
          {t("common:delete")}
        </Button>
      </div>
    </form>
  )
}
