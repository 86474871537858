import { createHttpClient } from "@intergamma/account"

import { mapPurchaseDetailsResponse } from "./mappers/mapPurchaseDetails"
import { mapPurchasesResponse } from "./mappers/mapPurchasesResponse"
import type {
  FindPurchasePayload,
  PurchaseDetailPayload,
  PurchaseDetailsResponse,
  PurchaseStatusPayload,
} from "./types/Purchase"
import { type Purchase, type PurchaseDetails, type PurchaseResponse } from "./types/Purchase"

export const PAGE_SIZE = 10

export function createPurchasesService(MYACCOUNT_URL: string) {
  const baseClient = createHttpClient(`${MYACCOUNT_URL}/api/purchases`, {
    withCredentials: true,
  })

  return {
    getLatestPurchase(): Promise<Purchase | null> {
      return baseClient
        .get<PurchaseResponse[]>("?page=0&size=1")
        .then((purchases) => (purchases.length > 0 ? purchases.map(mapPurchasesResponse)[0] : null))
    },

    getPurchases({ pageParam = 1 }): Promise<Purchase[]> {
      return baseClient
        .get<PurchaseResponse[]>(`?page=${pageParam - 1}&size=${PAGE_SIZE}`)
        .then((purchases) => purchases.map(mapPurchasesResponse))
    },

    getPurchaseDetails({ purchaseId }: PurchaseDetailPayload): Promise<PurchaseDetails> {
      return baseClient
        .get<PurchaseDetailsResponse>(`/${purchaseId}`)
        .then((purchaseDetails) => mapPurchaseDetailsResponse(purchaseDetails))
    },

    findPurchase({ purchaseId, postalCode }: FindPurchasePayload): Promise<PurchaseDetails> {
      return baseClient
        .get<PurchaseDetailsResponse>(`/detail/${purchaseId}?postalCode=${postalCode.replace(/ /g, "")}`)
        .then((purchaseDetails) => mapPurchaseDetailsResponse(purchaseDetails))
    },

    getPurchaseStatus({ purchaseId, hash, locale }: PurchaseStatusPayload): Promise<PurchaseDetails> {
      return baseClient
        .get<PurchaseDetailsResponse>(`/detail/${purchaseId}/${hash}/${locale}`)
        .then((purchaseDetails) => mapPurchaseDetailsResponse(purchaseDetails))
    },
  }
}
