import { dispatchAdobeEvent } from "@intergamma/adobe-tracking"
import { Correct } from "@intergamma/icons"
import { dispatchMobileAppEvent } from "@intergamma/mobile-app"

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { signOut } from "aws-amplify/auth"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"

import { useGatewayService } from "~/features/shared/ServicesContext"

import { log } from "~/helpers/tracking/datadog-logging"
import { TranslatedError } from "~/helpers/TranslatedError"

export function useRemoveAccount() {
  const gatewayService = useGatewayService()
  const navigate = useNavigate()

  const { t } = useTranslation(["profile", "errors", "urls"])
  const queryClient = useQueryClient()

  async function removeAccount() {
    dispatchAdobeEvent({
      type: "my_account",
      data: {
        user_selected_element: "delete account",
        user_selected_value: "delete account submit",
      },
    })

    await gatewayService
      .removeAccount()
      .then(() =>
        signOut().then(() => {
          dispatchAdobeEvent({
            type: "my_account",
            data: {
              user_selected_element: "delete account",
              user_selected_value: "delete account successful",
            },
          })
          toast.success(t("profile:removeAccountSuccess"), { icon: Correct })
        })
      )
      .catch((error) => {
        log.error(
          "Remove account failed",
          {
            reasons: error.response?.data?.reasons?.join(", ") ?? "Unknown",
          },
          error
        )

        if (error.response.status === 424) {
          throw new Error("424")
        }

        throw new TranslatedError(`${t("errors:UnknownErrorDescription")} ${t("errors:ContactCustomerSupport")}`)
      })
  }

  return useMutation({
    mutationFn: removeAccount,
    onSuccess: () => {
      queryClient.resetQueries()

      navigate(t("urls:/login"), { replace: true })

      dispatchMobileAppEvent({
        app: "myaccount",
        type: "IG_LOGOUT",
      })
    },
    throwOnError: false,
  })
}
