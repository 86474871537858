import { Outlet } from "react-router"
import { Navigation } from "~/features/navigation/Navigation"

export function AuthenticatedLayout() {
  return (
    <div className="relative w-full md:pt-32">
      <div className="absolute left-0 top-0 -z-10 hidden h-[300px] w-full bg-ignew-neutral-25 md:block"></div>
      <div className="mx-auto flex w-full max-w-[1200px] flex-col gap-6 rounded-t-md bg-white md:flex-row md:gap-8 md:pb-8 md:pl-4 md:pr-8 md:pt-8">
        <Navigation />
        <main className="grid w-full gap-4 p-4 md:p-0">
          <Outlet />
        </main>
      </div>
    </div>
  )
}
