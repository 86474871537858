import { useSuspenseQuery } from "@tanstack/react-query"

import { useSavedConfigurationsService } from "~/features/shared/ServicesContext"

export const QUERY_KEY = "saved-configurations"

export function useSavedConfigurations() {
  const savedConfigurationsService = useSavedConfigurationsService()

  return useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: savedConfigurationsService.getSavedConfigurations,
  })
}
