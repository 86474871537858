import { forwardRef, type SVGProps } from "react";

export interface StoreIconProps extends SVGProps<SVGSVGElement> {}

export const Store = forwardRef<SVGSVGElement, StoreIconProps>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      d="M5 7.20001C5 6.92387 5.22386 6.70001 5.5 6.70001H6.5C6.77614 6.70001 7 6.92387 7 7.20001V8.20001C7 8.47615 6.77614 8.70001 6.5 8.70001H5.5C5.22386 8.70001 5 8.47615 5 8.20001V7.20001Z"
      fill="currentColor"
    />
    <path
      d="M9.5 6.70001C9.22386 6.70001 9 6.92387 9 7.20001V8.20001C9 8.47615 9.22386 8.70001 9.5 8.70001H10.5C10.7761 8.70001 11 8.47615 11 8.20001V7.20001C11 6.92387 10.7761 6.70001 10.5 6.70001H9.5Z"
      fill="currentColor"
    />
    <path
      d="M13 7.20001C13 6.92387 13.2239 6.70001 13.5 6.70001H14.5C14.7761 6.70001 15 6.92387 15 7.20001V8.20001C15 8.47615 14.7761 8.70001 14.5 8.70001H13.5C13.2239 8.70001 13 8.47615 13 8.20001V7.20001Z"
      fill="currentColor"
    />
    <path
      d="M17.5 6.70001C17.2239 6.70001 17 6.92387 17 7.20001V8.20001C17 8.47615 17.2239 8.70001 17.5 8.70001H18.5C18.7761 8.70001 19 8.47615 19 8.20001V7.20001C19 6.92387 18.7761 6.70001 18.5 6.70001H17.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 5C0.25 3.48122 1.48122 2.25 3 2.25H21C22.5188 2.25 23.75 3.48122 23.75 5V20C23.75 20.9665 22.9665 21.75 22 21.75H2C1.0335 21.75 0.25 20.9665 0.25 20V5ZM3 3.75C2.30964 3.75 1.75 4.30964 1.75 5V20C1.75 20.1381 1.86193 20.25 2 20.25H5.25V13.407C5.25 12.4405 6.0335 11.657 7 11.657H17C17.9665 11.657 18.75 12.4405 18.75 13.407V20.25H22C22.1381 20.25 22.25 20.1381 22.25 20V5C22.25 4.30964 21.6904 3.75 21 3.75H3ZM11.25 13.157V20.25H6.75V13.407C6.75 13.269 6.86193 13.157 7 13.157H11.25ZM17.25 20.25V13.407C17.25 13.269 17.1381 13.157 17 13.157H12.75V20.25H17.25Z"
      fill="currentColor"
    />
  </svg>
));

(Store as React.FC).displayName = "Store";
