import { createHttpClient } from "@intergamma/account"

import type { AddLoyaltyCardBody, LoyaltyCard, LoyaltyCardResponse } from "./types/LoyaltyCards"

export function createLoyaltyCardsService(MYACCOUNT_URL: string) {
  const loyaltyCardsClient = createHttpClient(`${MYACCOUNT_URL}/api/loyaltycards`, {
    withCredentials: true,
  })

  return {
    getLoyaltyCards(): Promise<LoyaltyCard[]> {
      return loyaltyCardsClient.get<LoyaltyCardResponse[]>("/").then((cards) =>
        cards
          .map((card) => ({
            ...card.card,
          }))
          .sort((a, b) => Number(a.cardNumber) - Number(b.cardNumber))
      )
    },
    addLoyaltyCard(payload: AddLoyaltyCardBody): Promise<void> {
      return loyaltyCardsClient.post<void>("/", payload)
    },
    generateLoyaltyCard(): Promise<void> {
      return loyaltyCardsClient.post<void>("/generate")
    },
    removeLoyaltyCard(cardNumber: string): Promise<void> {
      return loyaltyCardsClient.delete<void>(`/${cardNumber}`)
    },
  }
}
