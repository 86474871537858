import { jsxs as p, jsx as e, Fragment as ie } from "react/jsx-runtime";
import { createContext as We, useContext as Ge, useMemo as oe, useState as C, useCallback as B, useEffect as E, useId as Be, useRef as Ye } from "react";
import { useTranslation as N, Trans as Se } from "react-i18next";
import { dispatchAdobeEvent as S } from "@intergamma/adobe-tracking";
import { cn as q } from "@intergamma/common/cn";
import { useConfig as H } from "@intergamma/config";
import { useActiveVariant as je } from "@intergamma/experiments/hooks";
import { Edit as we, Correct as Ne } from "@intergamma/icons";
import { PrivacyMask as Z } from "@intergamma/privacy-mask";
import { Button as ee } from "@intergamma/ui/button";
import { calculatePasswordStrength as Pe, useStrengthText as Le, CODE_LENGTH as X, TextField as ue, PasswordField as Ce, CheckboxField as ve, CodeField as xe } from "@intergamma/ui/form";
import { ExclamationMark as _e } from "@intergamma/ui/icons/ExclamationMark";
import { Snackbar as Ee } from "@intergamma/ui/snackbar";
import { zodResolver as me } from "@hookform/resolvers/zod";
import { useForm as ge, Controller as he } from "react-hook-form";
import { useSearchParams as He, useLocation as ze, Navigate as Ke, useNavigate as Qe } from "react-router";
import { c as ce, l as fe } from "./index-B17f4NLr.js";
import { useQuery as Je, useMutation as pe } from "@tanstack/react-query";
import { datadogRum as ne } from "@datadog/browser-rum";
import { isAxiosError as be } from "axios";
import { z as I } from "zod";
import Xe from "@intergamma/mailcheck";
import { isSyntheticTest as Ze } from "@intergamma/common/synthetic";
import { toast as ye } from "react-toastify";
import { signIn as et } from "aws-amplify/auth";
import { LoyaltyCard as tt } from "@intergamma/loyalty-card";
import { CheckMark as rt } from "@intergamma/ui/icons/CheckMark";
import { getMobileOperatingSystem as st, MobileOperatingSystem as nt } from "@intergamma/common/device";
import { getHeaders as ot } from "@intergamma/account/auth";
var k = /* @__PURE__ */ ((t) => (t[t.LOGIN = 0] = "LOGIN", t[t.SIGN_UP = 1] = "SIGN_UP", t[t.VERIFY = 2] = "VERIFY", t[t.FORGOT_PASSWORD = 3] = "FORGOT_PASSWORD", t))(k || {}), se = /* @__PURE__ */ ((t) => (t[t.LOGIN = 0] = "LOGIN", t[t.RESEND_VERIFICATION = 1] = "RESEND_VERIFICATION", t[t.RESEND_SUCCESS = 2] = "RESEND_SUCCESS", t))(se || {}), Ve = /* @__PURE__ */ ((t) => (t.CODE = "CODE", t.HASH = "HASH", t))(Ve || {});
const it = {
  signUpRequestBody: {
    email: "",
    password: "",
    locale: "nl",
    optIn: !1,
    verificationMethod: "CODE",
    passwordless: !1
  },
  setSignUpRequestBody: () => {
  },
  setSignUpResponse: () => {
  },
  loginAfterSignUp: !1,
  setLoginAfterSignUp: () => {
  },
  setVerificationError: () => {
  },
  setFlow: () => {
  }
}, Oe = We(it), at = Oe.Provider, de = () => Ge(Oe), Fe = (t, s, r) => ({
  gamma: {
    nl: {
      loyaltyProgram: `${r}/voordeelpas`,
      requirementsLoyalty: `${r}/klantenservice/algemene-voorwaarden/voordeelpas`
    },
    "nl-BE": {
      loyaltyProgram: `${r}/nl/klanteninformatie/gammapluskaart`,
      requirementsLoyalty: `${r}/nl/klanteninformatie/gammapluskaart?hp-gammaplus`
    },
    "fr-BE": {
      loyaltyProgram: `${r}/fr/infos-consommateur/carte-gammaplus`,
      requirementsLoyalty: `${r}/fr/infos-consommateur/carte-gammaplus`
    }
  },
  karwei: {
    nl: {
      loyaltyProgram: `${r}/clubkarwei`,
      requirementsLoyalty: `${r}/klantenservice/voorwaarden-veiligheid/algemene-voorwaarden/club-karwei`
    },
    karwei_nl: {
      loyaltyProgram: `${r}/clubkarwei`,
      requirementsLoyalty: `${r}/klantenservice/voorwaarden-veiligheid/algemene-voorwaarden/club-karwei`
    }
  }
})[t][s];
class F extends Error {
  constructor(s) {
    super(s), this.name = "TranslatedError";
  }
}
const lt = "account";
function Ue() {
  const { t } = N(["register", "errors"]), { MYACCOUNT_URL: s } = H(), r = oe(
    () => ce(s),
    [s]
  ), [a, i] = C(void 0), l = B(async () => a ? r.getAccountState(a).catch((c) => {
    throw S({
      type: "login_email_check_error",
      data: {
        error: (c == null ? void 0 : c.message) || ""
      }
    }), new F(
      `${t("errors:somethingWentWrong")} ${t(
        "errors:ContactCustomerSupport"
      )}`
    );
  }) : null, [a, r, t]), { data: o, isFetching: n, refetch: d, error: u } = Je({
    queryKey: [lt, a],
    queryFn: l,
    throwOnError: !1
  });
  return {
    accountState: o,
    isFetching: n,
    error: u,
    refetch: d,
    getAccountState: (c) => {
      i(c);
    }
  };
}
const Re = (t) => {
  switch (t) {
    case "nl_BE":
    case "nl-BE":
      return "nl_BE";
    case "fr_BE":
    case "fr-BE":
      return "fr_BE";
    default:
      return "nl";
  }
};
function ct({ onSuccess: t }) {
  const { MYACCOUNT_URL: s } = H(), { t: r, i18n: a } = N(["register", "errors"]), i = oe(
    () => ce(s),
    [s]
  ), l = B(() => {
    ne.addAction("signup");
  }, []);
  function o({ password: n, email: d, optIn: u, passwordless: c }) {
    return i.signUp({
      email: d,
      password: n,
      locale: Re(a.language),
      optIn: u,
      verificationMethod: "CODE",
      passwordless: c
    }).catch((g) => {
      var m, f, y;
      if (be(g) && ((f = (m = g.response) == null ? void 0 : m.data) != null && f.status)) {
        const A = ((y = g.response) == null ? void 0 : y.data).status;
        if (A === "UserSignUpInvalidEmail")
          throw new F(r("register:invalidEmail"));
        if (A === "UserSignUpPasswordInvalid")
          throw new F(r("register:invalidSignupPassword"));
        if (A === "UserSignUpAlreadyExists")
          throw new F(r("register:userAlreadyExists"));
      }
      throw fe.error(
        c ? "Passwordless signup" : "Signup",
        {
          details: "Failed"
        },
        g
      ), new F(
        `${r("errors:somethingWentWrong")} ${r(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return pe({
    mutationFn: o,
    throwOnError: !1,
    onSuccess: (n, d) => {
      l(), S({
        type: "sign_up_interaction",
        data: {
          user_selected_element: "Sign_up verification",
          user_message_value: `Commercial opt-in: ${d.optIn}`,
          user_selected_value: `Verification method: ${d.passwordless ? "passwordless" : "email"}`
        }
      }), t(n, d);
    },
    onError: (n) => {
      S({
        type: "sign_up_error",
        data: {
          error: n == null ? void 0 : n.message
        }
      });
    }
  });
}
function dt({
  isPasswordless: t
}) {
  const { t: s } = N(["register"]);
  return I.object({
    email: I.string().min(1, s("register:emailRequired")).email(s("register:emailInvalid")),
    ...t ? {} : {
      password: I.string().min(8, s("register:minPasswordLength")).max(50, s("register:maxPasswordLength"))
    },
    requirements: I.literal(!0, {
      errorMap: () => ({ message: s("register:requirementsError") })
    }),
    optIn: I.boolean()
  });
}
function ut({ setShowLoyaltyBenefits: t }) {
  var le, _;
  const { t: s, i18n: r } = N([
    "common",
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), [a] = He(), i = je("abtest_drl_3327-drl_1"), [l, o] = C(
    a.get("method") === "passwordless" || i !== "A-control"
  ), { signUpRequestBody: n, setSignUpRequestBody: d, setFlow: u } = de(), c = dt({
    isPasswordless: l
  }), {
    mutate: g,
    error: m,
    isPending: f
  } = ct({
    onSuccess: (w, O) => {
      d(($) => ({ ...$, ...O })), u({
        flow: k.VERIFY,
        verificationData: {
          customerId: w.customerId,
          email: O.email,
          hashedId: w.hashedId
        }
      }), t == null || t(!0), l && fe.info("Passwordless signup", {
        details: "Successful"
      });
    }
  }), [y, z] = C(null), { brand: A, SHOPFRONT_URL: v } = H(), { getAccountState: K, accountState: x, isFetching: Y } = Ue(), [M, D] = C(null), U = Fe(A, r.language, v), W = (U == null ? void 0 : U.requirementsLoyalty) ?? "/", V = (U == null ? void 0 : U.loyaltyProgram) ?? "/", {
    email: P,
    password: Q,
    optIn: h
  } = n, R = ge({
    defaultValues: {
      email: P,
      password: Q,
      requirements: !1,
      optIn: h
    },
    resolver: me(c)
  }), ae = R.watch("password"), G = oe(
    () => Pe(ae),
    [ae]
  ), j = Le(G);
  E(() => {
    m != null && m.message && D(m.message);
  }, [m]);
  const te = R.handleSubmit(
    (w) => {
      K(w.email), z({
        ...w,
        locale: "nl",
        // FIXME: get locale from somewhere?
        verificationMethod: "CODE",
        password: l ? window.crypto.randomUUID() : w.password,
        passwordless: l
      });
    },
    (w) => {
      for (const { message: O } of Object.values(w))
        S({
          type: "sign_up_error",
          data: {
            error: O
          }
        });
    }
  );
  return E(() => {
    x && !Y && (!x.hasAccount && y ? g({
      email: y.email,
      password: y.password,
      optIn: y.optIn,
      passwordless: y.passwordless
    }) : D(s("register:userAlreadyExists")));
  }, [
    x,
    Y,
    s,
    y,
    g,
    d
  ]), E(() => {
    S({
      type: "sign_up_interaction",
      data: {
        user_selected_element: "Sign-up pop-up form"
      }
    });
  }, []), /* @__PURE__ */ p("div", { className: "grid gap-4", children: [
    /* @__PURE__ */ e("h1", { className: "text-200 font-bold", children: s("login-form:buttonLabelNewAccount") }),
    l && i === "C-explanation" && /* @__PURE__ */ e("p", { children: s("register:passwordless", { length: X }) }),
    /* @__PURE__ */ p(
      "form",
      {
        noValidate: !0,
        onSubmit: (w) => (w.stopPropagation(), te(w)),
        className: "flex flex-col gap-4",
        children: [
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(Z, { children: /* @__PURE__ */ e("div", { className: "relative", children: /* @__PURE__ */ e(
            ue,
            {
              type: "email",
              label: s("common:EmailAddress"),
              error: (le = R.formState.errors.email) == null ? void 0 : le.message,
              autoCapitalize: "off",
              autoComplete: "email",
              disabled: !0,
              icon: /* @__PURE__ */ e(
                "button",
                {
                  className: q(
                    "bg-transparent border-none p-0 cursor-pointer text-2xl gamma:rounded-md",
                    "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                  ),
                  onClick: () => {
                    S({
                      type: "login_interaction",
                      data: {
                        user_selected_element: "Sign-up pop-up change e-mail link click"
                      }
                    }), u({ flow: k.LOGIN });
                  },
                  type: "button",
                  children: /* @__PURE__ */ e(we, {})
                }
              ),
              ...R.register("email")
            }
          ) }) }) }),
          !l && /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(Z, { children: /* @__PURE__ */ e(
            Ce,
            {
              autoComplete: "new-password",
              autoFocus: !0,
              label: s("login-form:Password"),
              error: (_ = R.formState.errors.password) == null ? void 0 : _.message,
              passwordStrength: G,
              description: j,
              ...R.register("password")
            }
          ) }) }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            he,
            {
              control: R.control,
              name: "requirements",
              defaultValue: !1,
              render: ({ field: w, fieldState: O }) => {
                var $;
                return /* @__PURE__ */ e(
                  ve,
                  {
                    ref: w.ref,
                    name: w.name,
                    checked: w.value,
                    "data-testid": "requirements-checkbox",
                    onCheckedChange: (b) => {
                      b !== "indeterminate" && w.onChange(b);
                    },
                    error: ($ = O.error) == null ? void 0 : $.message,
                    label: /* @__PURE__ */ e(
                      Se,
                      {
                        ns: "register",
                        i18nKey: "requirements",
                        defaultValue: "Met het aanmaken van een account, ga ik akkoord met de <terms-link>voorwaarden</terms-link> en profiteer ik van alle gratis voordelen van de <loyalty-link>{{loyaltyCardName}}</loyalty-link>",
                        components: {
                          "terms-link": /* @__PURE__ */ e(
                            "a",
                            {
                              target: "_blank",
                              href: W,
                              rel: "noreferrer",
                              className: "underline",
                              children: "voorwaarden"
                            }
                          ),
                          "loyalty-link": /* @__PURE__ */ e(
                            "a",
                            {
                              target: "_blank",
                              href: V,
                              rel: "noreferrer",
                              className: "underline",
                              children: "pas"
                            }
                          )
                        }
                      }
                    )
                  }
                );
              }
            }
          ) }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            he,
            {
              control: R.control,
              name: "optIn",
              defaultValue: !1,
              render: ({ field: w, fieldState: O }) => {
                var $;
                return /* @__PURE__ */ e(
                  ve,
                  {
                    ref: w.ref,
                    name: w.name,
                    checked: w.value,
                    onCheckedChange: (b) => {
                      b !== "indeterminate" && w.onChange(b);
                    },
                    error: ($ = O.error) == null ? void 0 : $.message,
                    label: s("register:optinNewsletter")
                  }
                );
              }
            }
          ) }),
          M && /* @__PURE__ */ e(Ee, { variant: "error", icon: /* @__PURE__ */ e(_e, {}), children: M }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            ee,
            {
              loading: Y || f,
              className: "w-full text-center",
              type: "submit",
              children: s("register:description")
            }
          ) })
        ]
      }
    ),
    l && /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
      "button",
      {
        className: q(
          "underline gamma:rounded-md",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        type: "button",
        onClick: () => o(!1),
        children: s("register:withPassword")
      }
    ) }),
    !l && i !== "A-control" && /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
      "button",
      {
        className: q(
          "underline gamma:rounded-md",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        type: "button",
        onClick: () => o(!0),
        children: s("register:withCode", { length: X })
      }
    ) })
  ] });
}
function Te() {
  const { MYACCOUNT_URL: t } = H(), { i18n: s, t: r } = N(["register"]), a = oe(
    () => ce(t),
    [t]
  ), i = B((o) => {
    ne.addAction("resend_verification_failed", {
      errorDetails: o == null ? void 0 : o.message
    });
  }, []);
  function l({
    email: o,
    sms: n,
    phoneNumber: d
  }) {
    return a.resendVerification({
      email: o,
      locale: Re(s.language),
      sms: n,
      phoneNumber: d,
      verificationMethod: "CODE"
    }).catch((u) => {
      var c;
      if (S({
        type: "resend_verification_error",
        data: {
          user_message_type: (u == null ? void 0 : u.message) || "",
          verificationMethod: n ? "sms" : "email"
        }
      }), be(u) && ((c = u.response) == null ? void 0 : c.data).status === "ResendInvalidEmail")
        throw new F(
          r(n ? "register:phoneNumberAlreadyExists" : "register:userAlreadyExists")
        );
    }).finally(() => {
      S({
        type: "resend_verification",
        data: {
          verificationMethod: n ? "sms" : "email"
        }
      });
    });
  }
  return pe({
    mutationFn: l,
    throwOnError: !1,
    onError: (o) => {
      i(o);
    }
  });
}
function mt({
  email: t,
  goToStep: s,
  goToFlow: r
}) {
  const { t: a } = N("login-form"), { mutate: i, isPending: l, isSuccess: o, data: n } = Te();
  E(() => {
    o && n && r({
      flow: k.VERIFY,
      verificationData: {
        customerId: n.customerId,
        email: n.email,
        hashedId: n.hashedId
      }
    });
  }, [o, s, r, n]);
  const d = () => {
    S({ type: "sign_up_change_email" }), s(se.LOGIN);
  };
  return /* @__PURE__ */ p("div", { className: "flex flex-col gap-4", children: [
    /* @__PURE__ */ p("div", { className: "grid gap-4", children: [
      /* @__PURE__ */ e("p", { children: a("bodyNoVerfication") }),
      /* @__PURE__ */ e("p", { children: a("bodyNoVerification2") }),
      /* @__PURE__ */ e(Z, { children: /* @__PURE__ */ e("p", { className: "text-center", children: /* @__PURE__ */ e("strong", { children: t }) }) })
    ] }),
    /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
      ee,
      {
        className: "w-full",
        loading: l,
        onClick: () => i({ email: t }),
        children: a("buttonLabelResendEmail")
      }
    ) }),
    /* @__PURE__ */ e("div", { className: "text-center", children: /* @__PURE__ */ e(
      "button",
      {
        className: q(
          "underline gamma:rounded-md",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        onClick: d,
        children: a("buttonLabelOtherEmail")
      }
    ) })
  ] });
}
function gt({ email: t }) {
  const { t: s } = N("login-form");
  return /* @__PURE__ */ p("div", { className: "grid gap-4", children: [
    /* @__PURE__ */ e("p", { children: s("bodyConfirmEmailSent") }),
    /* @__PURE__ */ e("p", { className: "text-center", children: /* @__PURE__ */ e(Z, { children: /* @__PURE__ */ e("strong", { children: t }) }) }),
    /* @__PURE__ */ e("p", { children: s("bodyEmailSteps") })
  ] });
}
function ft({ onSubmit: t, isLoading: s }) {
  const { t: r } = N("login-form"), { brand: a, country: i } = H();
  let l = [];
  a === "gamma" ? l = i === "be" || i === "BE" ? ["2613", "2614", "2617", "2618", "2629"] : ["2611", "2615", "2616"] : a === "karwei" && (l = ["2612", "2619"]);
  const n = I.object({
    loyaltyCardNumber: I.string().min(1, r("cardNumberRequired")).length(13, r("LoyaltyCardNumberInvalid")).refine((c) => l.some((g) => c.startsWith(g)), {
      message: r("LoyaltyCardNumberInvalid")
    }).refine(
      (c) => c.split("").reduce(
        (m, f, y) => y % 2 === 0 ? m + Number(f) : m + 3 * Number(f),
        0
      ) % 10 === 0,
      {
        message: r("LoyaltyCardNumberInvalid")
      }
    )
  }), d = ge({
    mode: "onBlur",
    defaultValues: {
      loyaltyCardNumber: ""
    },
    resolver: me(n)
  }), u = d.handleSubmit((c) => {
    t(c.loyaltyCardNumber);
  });
  return /* @__PURE__ */ p(ie, { children: [
    /* @__PURE__ */ p("div", { className: "relative flex p-4 items-center", children: [
      /* @__PURE__ */ e("div", { className: "flex-grow border-t border-ignew-neutral-500" }),
      /* @__PURE__ */ e("span", { className: "flex-shrink mx-4 text-ignew-neutral-500 uppercase", children: r("or") }),
      /* @__PURE__ */ e("div", { className: "flex-grow border-t border-ignew-neutral-500" })
    ] }),
    /* @__PURE__ */ p(
      "form",
      {
        noValidate: !0,
        onSubmit: (c) => (c.stopPropagation(), u(c)),
        className: "flex flex-col gap-4",
        children: [
          /* @__PURE__ */ e(
            he,
            {
              rules: {
                required: r("cardNumberRequired"),
                pattern: {
                  value: /^\d+$/,
                  message: r("LoyaltyCardNumberInvalid")
                }
              },
              render: ({ field: { onChange: c, ...g } }) => {
                var m;
                return /* @__PURE__ */ e(
                  ue,
                  {
                    onChange: (f) => {
                      (f.target.value === "" || /^[0-9\b]+$/.test(f.target.value)) && c(f.target.value);
                    },
                    type: "text",
                    label: r("enterLoyaltyCardNumber"),
                    maxLength: 13,
                    inputMode: "numeric",
                    error: (m = d.formState.errors.loyaltyCardNumber) == null ? void 0 : m.message,
                    ...g
                  }
                );
              },
              control: d.control,
              name: "loyaltyCardNumber",
              defaultValue: ""
            }
          ),
          /* @__PURE__ */ e(ee, { type: "submit", variant: "secondary", loading: s, children: r("RegisterLoyaltyCardNumber") })
        ]
      }
    )
  ] });
}
function pt({
  showTermsAndConditions: t,
  showPasswordField: s
}) {
  const { t: r } = N(["ig-header", "login"]);
  return I.object({
    email: I.string().min(1, r("ig-header:loginEmailRequired")).email(r("ig-header:loginEmailInvalid")),
    password: s ? I.string().min(1, r("ig-header:loginRequired")) : I.string(),
    requirements: t ? I.literal(!0, {
      errorMap: () => ({ message: r("login:requirementsError") })
    }) : I.any()
  });
}
function ht({
  isLoading: t,
  onLogin: s,
  errorMessage: r,
  setShowLoyaltyBenefits: a,
  loyaltyCardLogin: i,
  initLocation: l,
  loginData: o
}) {
  var Ie;
  const { t: n, i18n: d } = N([
    "ig-header",
    "login",
    "urls",
    "login-form",
    "register",
    "common"
  ]), u = ze(), { signUpRequestBody: c, setSignUpRequestBody: g, setFlow: m, loginAfterSignUp: f } = de(), { brand: y, SHOPFRONT_URL: z } = H(), A = Be(), [v, K] = C(
    !!(o != null && o.email)
  ), [x, Y] = C(void 0), [M, D] = C(!1), [U, W] = C(se.LOGIN), V = Fe(y, d.language, z), P = (V == null ? void 0 : V.requirementsLoyalty) ?? "/", Q = (V == null ? void 0 : V.loyaltyProgram) ?? "/", {
    accountState: h,
    getAccountState: R,
    refetch: ae,
    error: G,
    isFetching: j
  } = Ue(), te = f ? c.email : ((Ie = u.state) == null ? void 0 : Ie.email) || (o == null ? void 0 : o.email), le = pt({
    showTermsAndConditions: M,
    showPasswordField: v
  }), _ = ge({
    defaultValues: {
      email: te || "",
      password: "",
      requirements: !1
    },
    resolver: me(le)
  }), w = () => {
    x && (_.setValue("email", x.full), Y(void 0), S({ type: "email_suggestion_click" }));
  }, O = _.watch("email"), $ = async (L) => {
    const re = await _.trigger("email");
    L && re && (L === c.email && h ? ae() : R(L), g((J) => ({
      ...J,
      email: L,
      password: ""
    })), S({ type: "login_email_check" }));
  }, b = B(
    (L) => {
      L != null && L.length && (h != null && h.customerId) && (S({
        type: "login_interaction",
        data: {
          user_selected_element: "Login by code"
        }
      }), m({
        flow: k.VERIFY,
        verificationData: {
          email: L,
          customerId: h.customerId,
          loginViaCode: !0,
          hashedId: h.hashedId || ""
        }
      }));
    },
    [h, m]
  );
  E(() => {
    o && !h && !j && R(o.email);
  }, [o, h, j, R]), E(() => {
    if (h && !j) {
      const { hasAccount: L, hasLoyaltyCard: re, verified: J } = h;
      L ? J ? o != null && o.forcePasswordFlow || Ze || h.preferredLoginMethod === "PASSWORD" && h.hasPassword ? (K(!0), _.setFocus("password")) : b(c.email) : W(se.RESEND_VERIFICATION) : m({ flow: k.SIGN_UP }), L && !re && (D(!0), typeof a < "u" && a(!0));
    }
  }, [
    h,
    j,
    a,
    A,
    m,
    c.email
  ]), E(() => {
    f && typeof a < "u" && a(!0);
  }, [f, a]), E(() => {
    U === se.LOGIN && S({
      type: "login_interaction",
      data: {
        user_selected_element: v ? "Login pop-up" : "Login / Signup email check pop-up",
        ...!v && { user_selected_value: l }
      }
    });
  }, [U, v, l]);
  const De = _.handleSubmit((L) => {
    const { email: re, password: J } = L;
    h != null && h.customerId && J && s({
      body: {
        customerId: h.customerId,
        email: re,
        password: J,
        hashedId: h.hashedId || ""
      }
    });
  });
  return /* @__PURE__ */ e("div", { "data-testid": "login-form", children: ((L) => {
    var re, J;
    switch (L) {
      case se.LOGIN:
        return /* @__PURE__ */ p(ie, { children: [
          /* @__PURE__ */ p(
            "form",
            {
              noValidate: !0,
              onSubmit: (T) => {
                T.stopPropagation(), De(T);
              },
              className: "flex flex-col gap-4",
              children: [
                /* @__PURE__ */ e("h4", { className: "text-200 font-bold", children: n(v ? "login-form:loginTitle" : "login-form:titleLogInOrSignUp") }),
                /* @__PURE__ */ e("p", { children: v ? /* @__PURE__ */ p(ie, { children: [
                  n("login-form:bodyWelcomeBack"),
                  /* @__PURE__ */ e("br", {}),
                  n("login-form:bodyPasswordRequest")
                ] }) : n("login-form:bodyUnsureAccount") }),
                /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(Z, { children: /* @__PURE__ */ e("div", { className: "relative", children: /* @__PURE__ */ e(
                  ue,
                  {
                    type: "email",
                    label: n("common:EmailAddress"),
                    error: (re = _.formState.errors.email) == null ? void 0 : re.message,
                    autoCapitalize: "off",
                    autoComplete: "email",
                    disabled: v,
                    "data-testid": "login-email-input",
                    description: x ? /* @__PURE__ */ p("span", { role: "alert", children: [
                      n("common:DidYouMean"),
                      " ",
                      /* @__PURE__ */ p(
                        "button",
                        {
                          className: q(
                            "underline",
                            "gamma:rounded-md outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                          ),
                          onClick: w,
                          children: [
                            "@",
                            x.domain
                          ]
                        }
                      ),
                      "?"
                    ] }) : void 0,
                    icon: v && /* @__PURE__ */ e(
                      "button",
                      {
                        className: q(
                          "bg-transparent border-none p-0 cursor-pointer text-2xl gamma:rounded-md",
                          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                        ),
                        "data-testid": "login-change-email",
                        onClick: (T) => {
                          T.preventDefault(), S({
                            type: "login_interaction",
                            data: {
                              user_selected_element: "Login pop-up change e-mail link click"
                            }
                          }), _.setValue("email", ""), K(!1);
                        },
                        type: "button",
                        children: /* @__PURE__ */ e(we, {})
                      }
                    ),
                    ..._.register("email", {
                      onChange(T) {
                        Xe(
                          T.target.value,
                          Y
                        );
                      }
                    })
                  }
                ) }) }) }),
                /* @__PURE__ */ e(
                  "div",
                  {
                    className: v ? "" : "h-0 overflow-hidden absolute",
                    children: /* @__PURE__ */ e(Z, { children: /* @__PURE__ */ e(
                      Ce,
                      {
                        ...!v && {
                          tabIndex: -1,
                          "aria-hidden": "true"
                        },
                        autoComplete: v ? "current-password" : "off",
                        label: n("ig-header:loginPasswordLabel"),
                        error: ((J = _.formState.errors.password) == null ? void 0 : J.message) || r,
                        ..._.register("password")
                      }
                    ) })
                  }
                ),
                M && /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
                  he,
                  {
                    control: _.control,
                    name: "requirements",
                    defaultValue: !1,
                    render: ({ field: T, fieldState: $e }) => {
                      var ke;
                      return /* @__PURE__ */ e(
                        ve,
                        {
                          ref: T.ref,
                          name: T.name,
                          checked: T.value,
                          onCheckedChange: (Ae) => {
                            Ae !== "indeterminate" && T.onChange(Ae);
                          },
                          error: (ke = $e.error) == null ? void 0 : ke.message,
                          label: /* @__PURE__ */ p(Se, { ns: "login", i18nKey: "requirements", children: [
                            "Met het aanmaken van een account, ga ik akkoord met de",
                            /* @__PURE__ */ e(
                              "a",
                              {
                                target: "_blank",
                                href: P,
                                rel: "noreferrer",
                                children: "voorwaarden"
                              }
                            ),
                            "en profiteer ik van alle gratis voordelen van de",
                            /* @__PURE__ */ e(
                              "a",
                              {
                                target: "_blank",
                                href: Q,
                                rel: "noreferrer",
                                children: "pas"
                              }
                            )
                          ] })
                        }
                      );
                    }
                  }
                ) }),
                (G == null ? void 0 : G.message) && /* @__PURE__ */ e(Ee, { variant: "error", icon: /* @__PURE__ */ e(_e, {}), children: G.message }),
                /* @__PURE__ */ e("div", { children: v ? /* @__PURE__ */ e(
                  ee,
                  {
                    loading: t,
                    className: "w-full text-center",
                    "data-testid": "login-submit",
                    type: "submit",
                    children: n("ig-header:Login")
                  }
                ) : /* @__PURE__ */ e(
                  ee,
                  {
                    loading: j,
                    className: "w-full text-center",
                    onClick: () => $(O),
                    "data-testid": "login-next",
                    children: n("login-form:buttonlabel")
                  }
                ) }),
                v && h && /* @__PURE__ */ p("div", { className: "text-center", children: [
                  /* @__PURE__ */ e("div", { className: "mb-4", children: /* @__PURE__ */ e(
                    "button",
                    {
                      className: q(
                        "underline gamma:rounded-md",
                        "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                      ),
                      onClick: (T) => {
                        T.preventDefault(), S({
                          type: "login_interaction",
                          data: {
                            user_selected_element: "Login pop-up password reset link click"
                          }
                        }), m({
                          flow: k.FORGOT_PASSWORD,
                          forgotPasswordData: {
                            email: _.getValues().email,
                            customerId: h.customerId,
                            hashedId: h.hashedId || ""
                          }
                        });
                      },
                      children: n("ig-header:loginForgotPassword")
                    }
                  ) }),
                  /* @__PURE__ */ e(
                    "button",
                    {
                      className: q(
                        "underline gamma:rounded-md",
                        "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                      ),
                      onClick: () => {
                        b(_.getValues().email);
                      },
                      children: n("login-form:loginWithCodeLink")
                    }
                  )
                ] })
              ]
            }
          ),
          typeof i < "u" && /* @__PURE__ */ e(
            ft,
            {
              onSubmit: i,
              isLoading: t
            }
          )
        ] });
      case se.RESEND_VERIFICATION:
        return /* @__PURE__ */ p(ie, { children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: n("login-form:loginTitle") }),
          /* @__PURE__ */ e(
            mt,
            {
              email: c.email,
              goToStep: W,
              goToFlow: m
            }
          )
        ] });
      case se.RESEND_SUCCESS:
        return /* @__PURE__ */ p(ie, { children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: n("register:titleIsThatYou") }),
          /* @__PURE__ */ e(gt, { email: c.email })
        ] });
      default:
        return null;
    }
  })(U) });
}
function wt() {
  const { MYACCOUNT_URL: t } = H(), { setFlow: s, setSignUpResponse: r, setVerificationError: a } = de(), { t: i } = N(["register", "errors", "login"]), l = B(() => {
    ne.addAction("verified_code");
  }, []), o = B((u) => {
    ne.addAction("verified_code_failed", {
      errorDetails: u == null ? void 0 : u.message
    });
  }, []), n = oe(
    () => ce(t),
    [t]
  );
  function d({ verificationCode: u, customerId: c }) {
    return n.verify("signup", `${c}_${u}`, c).catch((g) => {
      var m;
      if (be(g)) {
        const f = (m = g.response) == null ? void 0 : m.data;
        if (f.status === "VerifySignUpInvalidToken")
          if (f.httpStatus === 410)
            r(void 0), a(i("register:tooManyWrongAttempts")), s({ flow: k.SIGN_UP });
          else
            throw new F(i("register:errorNotValidCode"));
      }
      throw fe.error("Verify code form failed", {}, g), (g == null ? void 0 : g.response.status) === 429 ? new F(i("errors:tooManyVerificationAttempts")) : new F(
        `${i("errors:somethingWentWrong")} ${i(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return pe({
    mutationFn: d,
    throwOnError: !1,
    onSuccess: () => {
      S({
        type: "verify_email"
      }), l();
    },
    onError: (u) => {
      S({
        type: "verify_email_error",
        data: {
          error: u == null ? void 0 : u.message
        }
      }), o(u);
    }
  });
}
function bt() {
  const [t, s] = C(!1), r = B(async (a) => {
    s(!0);
    try {
      return await et({
        username: a,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP"
        }
      });
    } catch {
      return null;
    } finally {
      s(!1);
    }
  }, []);
  return {
    isPending: t,
    requestVerificationCode: r
  };
}
const qe = () => {
  const { t } = N(["register"]);
  return ge({
    defaultValues: {
      code: ""
    },
    resolver: me(
      I.object({
        code: I.string({ required_error: t("register:errorNotValidCode") }).min(X, t("register:errorNotValidCode")).regex(/^\d+$/, t("register:errorNotValidCode"))
      })
    ),
    mode: "onSubmit",
    resetOptions: {
      keepDirtyValues: !0
    }
  });
};
function yt({
  navigateToLogin: t = !1,
  email: s,
  customerId: r,
  hashedId: a
}) {
  const { setFlow: i, setLoginAfterSignUp: l } = de(), { t: o } = N(["verification", "common", "urls"]);
  return E(() => {
    ye.success(o("verification:signupVerificationSuccessDescription"), {
      icon: Ne
    }), i({ flow: k.LOGIN }), l(!0), S({
      type: "sign_up_activation",
      data: { kdbid: r, kdbid_hashed: a }
    });
  }, [o, i, l, r, a]), t ? /* @__PURE__ */ e(
    Ke,
    {
      to: { pathname: o("urls:/login"), search: "type=new" },
      state: { email: s },
      replace: !0
    }
  ) : null;
}
function vt({
  verificationData: t,
  navigateToLogin: s = !1,
  onLogin: r,
  confirmSignInError: a
}) {
  var $;
  const { customerId: i, hashedId: l, email: o, loginViaCode: n } = t, { t: d } = N(["register", "common", "login-form"]), [u, c] = C(null), [g, m] = C(!1), [f, y] = C(!1), { setFlow: z, signUpRequestBody: A, setVerificationError: v } = de(), K = qe(), {
    register: x,
    resetField: Y,
    setFocus: M,
    setValue: D,
    watch: U,
    formState: { errors: W }
  } = K, {
    isPending: V,
    requestVerificationCode: P
  } = bt(), Q = Ye(r), { mutate: h, error: R } = Te(), {
    mutate: ae,
    isSuccess: G,
    isError: j,
    error: te
  } = wt();
  E(() => {
    M("code");
  }, [M]), E(() => {
    n && P(i);
  }, [n, i, P]), E(() => {
    j && y(!1);
  }, [j]), E(() => {
    var b;
    G && Q.current && ((b = A.password) != null && b.length) ? (S({
      type: "sign_up_activation",
      data: { kdbid: i, kdbid_hashed: l }
    }), Q.current({
      body: {
        email: o,
        customerId: i,
        password: A.password,
        hashedId: l,
        signup: !0
      },
      disableAdobeEvent: !0
    })) : y(!1);
  }, [G, Q, A, i, o, l]), E(() => {
    const b = (te == null ? void 0 : te.message) || (R == null ? void 0 : R.message);
    b && (D("code", ""), c(b));
  }, [R, te, D]);
  const le = (b) => {
    b.preventDefault(), Y("code"), M("code"), c(null), t.loginViaCode ? P(i) : h({
      email: o
    }), ye.success(d("register:successMessageResendCode"), { icon: Ne }), m(!0), setTimeout(() => {
      m(!1);
    }, 3e4);
  }, _ = (b) => {
    S({
      type: "login_interaction",
      data: {
        user_selected_element: "Login by password"
      }
    }), z({
      flow: k.LOGIN,
      loginData: {
        email: b,
        // If a user has no password it would not show the password input.
        // This forces the input to show up.
        forcePasswordFlow: !0
      }
    });
  };
  E(() => {
    a && (y(!1), c(a), D("code", ""));
  }, [a, D]);
  const w = K.handleSubmit((b) => {
    v(void 0), i && !n && (c(null), y(!0), ae({ verificationCode: b.code, customerId: i })), n && Q.current && (c(null), y(!0), Q.current({
      body: {
        email: o,
        customerId: i,
        verificationCode: b.code,
        hashedId: l
      }
    }));
  }), O = U("code");
  return E(() => {
    !V && (O == null ? void 0 : O.length) === X && w();
  }, [O, V]), G && !f ? /* @__PURE__ */ e(
    yt,
    {
      navigateToLogin: s,
      email: o,
      hashedId: l,
      customerId: i
    }
  ) : /* @__PURE__ */ p("div", { className: "flex flex-col gap-4", children: [
    /* @__PURE__ */ e("h4", { className: "text-200 font-bold", children: d(n ? "login-form:loginCodeSentTitle" : "register:titleIsThatYou") }),
    /* @__PURE__ */ e("div", { children: d(n ? "login-form:loginCodeSentDescription" : "register:bodyCodeSentTo") }),
    /* @__PURE__ */ e(Z, { children: /* @__PURE__ */ e(
      ue,
      {
        label: "",
        value: o,
        disabled: !0,
        icon: /* @__PURE__ */ e(
          "button",
          {
            className: q(
              "underline bg-transparent gamma:rounded-md",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            onClick: () => {
              z({ flow: k.LOGIN }), S({ type: "sign_up_change_email" });
            },
            "data-testid": "change-email-button",
            type: "button",
            children: /* @__PURE__ */ e(we, {})
          }
        )
      }
    ) }),
    /* @__PURE__ */ e("p", { children: d(n ? "login-form:codeValidity" : "register:bodyFillCode") }),
    /* @__PURE__ */ p(
      "form",
      {
        className: "flex flex-col gap-4",
        noValidate: !0,
        onSubmit: w,
        method: "POST",
        children: [
          /* @__PURE__ */ e(
            xe,
            {
              ...x("code"),
              label: d("login-form:verifyNewPasswordCodeLabel", {
                length: X
              }),
              description: d("login-form:verifyNewPasswordCodeDescription"),
              error: (($ = W.code) == null ? void 0 : $.message) || u
            }
          ),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            ee,
            {
              type: "submit",
              className: "w-full",
              loading: f || V,
              children: d(f ? "register:isVerifying" : "register:confirm")
            }
          ) }),
          /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              type: "button",
              className: q(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              disabled: g,
              onClick: (b) => le(b),
              children: d("register:buttonLabelResendCode")
            }
          ) }),
          n && /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              type: "button",
              className: q(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              onClick: () => {
                _(o);
              },
              children: d("login-form:loginPasswordLink")
            }
          ) })
        ]
      }
    )
  ] });
}
function Me() {
  const { MYACCOUNT_URL: t } = H(), { t: s } = N(["register", "errors"]), r = oe(
    () => ce(t),
    [t]
  ), a = B(() => {
    ne.addAction("request_new_password");
  }, []), i = B(
    (o) => {
      ne.addAction("request_new_password_failed", {
        reason: o
      });
    },
    []
  );
  function l(o) {
    return r.passwordReset({ email: o, verificationMethod: Ve.CODE }).catch((n) => {
      throw fe.error("Reset password request failed", {}, n), (n == null ? void 0 : n.response.status) === 429 ? new F(s("errors:tooManyVerificationAttempts")) : new F(
        `${s("errors:somethingWentWrong")} ${s(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return pe({
    mutationFn: l,
    onSuccess: () => {
      a();
    },
    onError: (o) => {
      i((o == null ? void 0 : o.message) || "");
    }
  });
}
function St({ email: t, onSuccess: s }) {
  var m;
  const { t: r } = N([
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), a = I.object({
    password: I.string().min(8, r("register:minPasswordLength")).max(50, r("register:maxPasswordLength"))
  }), i = ge({
    defaultValues: {
      password: ""
    },
    resolver: me(a)
  }), l = i.watch("password"), o = oe(
    () => Pe(l),
    [l]
  ), n = Le(o), { mutate: d, isPending: u, error: c } = Me(), g = i.handleSubmit((f) => {
    d(t, {
      onSuccess() {
        s(f.password);
      }
    });
  });
  return /* @__PURE__ */ p(
    "form",
    {
      noValidate: !0,
      onSubmit: (f) => {
        f.stopPropagation(), g(f);
      },
      className: "flex flex-col gap-4",
      children: [
        /* @__PURE__ */ p("div", { className: "grid gap-4", children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: r("login-form:chooseNewPasswordTitle") }),
          /* @__PURE__ */ e("p", { children: r("login-form:chooseNewPasswordBody", {
            length: X
          }) })
        ] }),
        /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(Z, { children: /* @__PURE__ */ e(
          Ce,
          {
            autoFocus: !0,
            autoComplete: "new-password",
            label: r("login-form:Password"),
            error: (m = i.formState.errors.password) == null ? void 0 : m.message,
            passwordStrength: o,
            description: n,
            ...i.register("password")
          }
        ) }) }),
        (c == null ? void 0 : c.message) && /* @__PURE__ */ e(Ee, { variant: "error", icon: /* @__PURE__ */ e(_e, {}), children: c.message }),
        /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
          ee,
          {
            disabled: u,
            loading: u,
            className: "w-full text-center",
            children: r("login-form:buttonlabel")
          }
        ) })
      ]
    }
  );
}
function Nt() {
  const { MYACCOUNT_URL: t } = H(), { t: s } = N(["register", "errors"]), r = oe(
    () => ce(t),
    [t]
  ), a = B(() => {
    ne.addAction("verified_new_password");
  }, []), i = B((o) => {
    ne.addAction("verifiy_new_password_failed", {
      reason: o
    });
  }, []);
  function l(o) {
    return r.verifyPasswordReset(o).catch((n) => {
      var d;
      if (be(n)) {
        const u = (d = n.response) == null ? void 0 : d.data;
        if (u.status === "VerifyPasswordResetInvalidToken")
          throw u.httpStatus === 410 ? new F(s("register:tooManyWrongAttempts")) : new F(s("register:errorNotValidCode"));
      }
      throw fe.error("Verify code form failed", {}, n), (n == null ? void 0 : n.response.status) === 429 ? new F(s("errors:tooManyVerificationAttempts")) : new F(
        `${s("errors:somethingWentWrong")} ${s(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return pe({
    mutationFn: l,
    throwOnError: !1,
    onSuccess: () => {
      S({ type: "login_change_password" }), a();
    },
    onError: (o) => {
      i((o == null ? void 0 : o.message) || "");
    }
  });
}
function Ct({
  email: t,
  password: s,
  customerId: r,
  onSuccess: a
}) {
  var V;
  const { t: i } = N([
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), [l, o] = C(null), n = qe(), {
    register: d,
    setValue: u,
    setFocus: c,
    watch: g,
    resetField: m,
    formState: { errors: f }
  } = n, { setFlow: y } = de(), {
    mutate: z,
    isPending: A,
    error: v
  } = Nt(), { mutate: K, error: x } = Me(), [Y, M] = C(!1), D = n.handleSubmit((P) => {
    o(null), z(
      { newPassword: s, customerId: r, code: P.code },
      { onSuccess: a }
    );
  });
  E(() => {
    const P = (v == null ? void 0 : v.message) || (x == null ? void 0 : x.message);
    P && o(P), c("code");
  }, [x, v, c, u]);
  const U = (P) => {
    P.preventDefault(), K(t, {
      onSuccess() {
        ye.success(i("register:successMessageResendCode"), {
          icon: Ne
        }), o(null), m("code"), c("code");
      }
    }), M(!0), setTimeout(() => {
      M(!1);
    }, 3e4);
  }, W = g("code");
  return E(() => {
    !A && (W == null ? void 0 : W.length) === X && D();
  }, [W]), /* @__PURE__ */ p(ie, { children: [
    /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: i("login-form:verifyNewPasswordTitle") }),
    /* @__PURE__ */ e("div", { className: "mb-4", children: i("login-form:verifyNewPasswordBody", { length: X }) }),
    /* @__PURE__ */ e(Z, { children: /* @__PURE__ */ e(
      ue,
      {
        label: "",
        value: t,
        disabled: !0,
        icon: /* @__PURE__ */ e(
          "button",
          {
            className: q(
              "underline bg-transparent gamma:rounded-md",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            onClick: () => y({ flow: k.LOGIN }),
            "data-testid": "change-email-button",
            type: "button",
            children: /* @__PURE__ */ e(we, {})
          }
        )
      }
    ) }),
    /* @__PURE__ */ p(
      "form",
      {
        className: "flex flex-col gap-4 mt-4",
        noValidate: !0,
        onSubmit: D,
        method: "POST",
        children: [
          /* @__PURE__ */ e(
            xe,
            {
              ...d("code"),
              label: i("login-form:verifyNewPasswordCodeLabel", {
                length: X
              }),
              error: ((V = f.code) == null ? void 0 : V.message) || l,
              disabled: A,
              description: i("login-form:verifyNewPasswordCodeDescription")
            }
          ),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(ee, { className: "w-full", loading: A, children: i(A ? "register:isVerifying" : "register:confirm") }) }),
          /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              className: q(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              disabled: Y,
              onClick: (P) => U(P),
              children: i("register:buttonLabelResendCode")
            }
          ) })
        ]
      }
    )
  ] });
}
function _t({
  values: t,
  onLogin: s
}) {
  const [r, a] = C({
    step: "CHOOSE_PASSWORD",
    email: t.email
  }), { t: i } = N(["change-password"]);
  return /* @__PURE__ */ p(ie, { children: [
    r.step === "CHOOSE_PASSWORD" && /* @__PURE__ */ e(
      St,
      {
        email: r.email,
        onSuccess: (l) => {
          a({
            step: "VERIFY_REQUEST",
            email: t.email,
            password: l
          });
        }
      }
    ),
    r.step === "VERIFY_REQUEST" && /* @__PURE__ */ e(
      Ct,
      {
        customerId: t.customerId,
        email: r.email,
        password: r.password,
        onSuccess: () => {
          ye.success(i("change-password:successfulSave")), s({
            body: {
              email: r.email,
              customerId: t.customerId,
              password: r.password,
              hashedId: t.hashedId
            },
            disableAdobeEvent: !0
          });
        }
      }
    )
  ] });
}
function rr(t) {
  const [s, r] = C({ flow: k.LOGIN }), [a, i] = C(), [l, o] = C(void 0), { i18n: n } = N(), [d, u] = C(!1), [c, g] = C(
    {
      email: "",
      password: "",
      locale: Re(n.language),
      optIn: !1,
      verificationMethod: "CODE",
      passwordless: !1
    }
  );
  return /* @__PURE__ */ e("div", { className: "px-4 py-6", children: /* @__PURE__ */ p(at, { value: {
    signUpRequestBody: c,
    setSignUpRequestBody: g,
    setFlow: r,
    loginAfterSignUp: d,
    setLoginAfterSignUp: u,
    signUpResponse: a,
    setSignUpResponse: i,
    setVerificationError: o,
    verificationError: l
  }, children: [
    s.flow === k.LOGIN && /* @__PURE__ */ e(ht, { ...t, loginData: s.loginData }),
    s.flow === k.SIGN_UP && /* @__PURE__ */ e(
      ut,
      {
        setShowLoyaltyBenefits: t.setShowLoyaltyBenefits
      }
    ),
    s.flow === k.VERIFY && /* @__PURE__ */ e(
      vt,
      {
        verificationData: s.verificationData,
        navigateToLogin: t.isMyAccount,
        confirmSignInError: t.errorMessage,
        onLogin: t.onLogin
      }
    ),
    s.flow === k.FORGOT_PASSWORD && /* @__PURE__ */ e(
      _t,
      {
        values: s.forgotPasswordData,
        onLogin: t.onLogin
      }
    )
  ] }) });
}
function Et() {
  const { brand: t, MYACCOUNT_URL: s } = H(), r = `${s}/api/app/loyalty/cards/apple-wallet-pass`;
  return async (a) => {
    const i = a.currentTarget;
    if (!(i != null && i.href))
      try {
        const l = await ot(), n = await (await fetch(r, {
          headers: {
            ...l,
            Accept: "application/vnd.apple.pkpass"
          }
        })).blob(), d = window.URL.createObjectURL(n);
        i.download = `${t}.pkpass`, i.href = d, i.click(), i.removeAttribute("href"), i.removeAttribute("download"), window.URL.revokeObjectURL(d);
      } catch {
        throw new Error("Failed to download loyalty card");
      }
  };
}
function sr({
  loyaltyCardNumber: t,
  close: s
}) {
  const { t: r, i18n: a } = N(["urls", "loyalty-benefits"]), i = Et(), l = Qe(), o = () => {
    s(), l(r("urls:/profile/loyaltycards"), {
      state: { showForm: !0 },
      replace: !0
    });
  }, n = st() === nt.IOS;
  return /* @__PURE__ */ p("div", { className: "p-0 md:p-4 grid grid-cols-2 gap-4", children: [
    /* @__PURE__ */ p("div", { className: "col-span-2", children: [
      /* @__PURE__ */ e("h3", { className: "mb-6 text-200/6 pr-6", children: r("loyalty-benefits:titleCongratsNewVdp") }),
      n && /* @__PURE__ */ e("div", { className: "mb-6 text-75/6", children: r("loyalty-benefits:welcomeAddWallet") }),
      /* @__PURE__ */ p("div", { className: "flex flex-row", children: [
        /* @__PURE__ */ e("div", { className: "w-6 h-6 mr-1", children: /* @__PURE__ */ e(rt, { className: "gamma:text-brand primary karwei:text-ignew-neutral-1000" }) }),
        /* @__PURE__ */ e("p", { children: /* @__PURE__ */ p(Se, { t: r, i18nKey: "loyalty-benefits:welcomeBenefits", children: [
          /* @__PURE__ */ e("strong", { children: "250" }),
          " welkomstpunten t.w.v. €2,50 zijn toegevoegd aan je account"
        ] }) })
      ] })
    ] }),
    /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e("div", { className: "w-full", children: /* @__PURE__ */ e(
      tt,
      {
        language: a.language,
        cardNumber: t
      }
    ) }) }),
    n && /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e("a", { onClick: i, className: "cursor-pointer", children: /* @__PURE__ */ e(
      "img",
      {
        src: a.language === "nl" ? "https://mijn.gamma.nl/static/images/apple_wallet_nl_full.svg" : "https://mijn.gamma.nl/static/images/apple_wallet_fr_full.svg",
        className: "w-full",
        alt: "Apple Wallet"
      }
    ) }) }),
    /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e(ee, { onClick: o, className: "w-full", children: r("loyalty-benefits:buttonAddPhysicalPass") }) })
  ] });
}
export {
  rr as A,
  k as F,
  sr as L,
  gt as R,
  ut as S,
  vt as V,
  Oe as a,
  at as b,
  ht as c,
  ft as d,
  mt as e,
  yt as f,
  Fe as g,
  Ue as h,
  it as i,
  Et as j,
  Te as k,
  ct as l,
  qe as m,
  wt as n,
  se as o,
  Ve as p,
  de as u
};
