import { useCallback, useMemo, useState } from "react"

import { useConfig } from "@intergamma/config"
import type { Category } from "@intergamma/header"
import { createGetAssortimentCategories } from "@intergamma/header"

import { keepPreviousData, useQuery } from "@tanstack/react-query"

export const QUERY_KEY = "base-navigation"

export function useCategoriesQuery() {
  const [activeCategory, setActiveCategory] = useState<Category | undefined>(undefined)

  const config = useConfig()
  const getAssortimentCategories = useMemo(() => createGetAssortimentCategories(config), [config])

  const getCurrentCategories = useCallback(async () => {
    if (!activeCategory) {
      return []
    }

    return getAssortimentCategories(activeCategory.dataUrl)
  }, [activeCategory, getAssortimentCategories])

  const { data: categories } = useQuery({
    queryKey: [QUERY_KEY, activeCategory?.uid],
    queryFn: getCurrentCategories,
    placeholderData: keepPreviousData,
    throwOnError: false,
  })

  function onSelect(category: Category | undefined) {
    setActiveCategory(category)
  }

  return { categories, onSelect }
}
