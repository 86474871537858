import type { BaseConfig } from "./BaseConfig"

export interface UrlConfig {
  nl: UrlsFromConfig
  fr?: UrlsFromConfig
}

export interface UrlsFromConfig {
  loyaltyProgram: string
  returnRequirements: string
  returnCosts: string
  returnFaqs: string
  returnToShopFaqs: string
  privacy: string
  customerService: string
  warranty: string
  store: string
}

export function getUrlConfig(baseConfig: BaseConfig): UrlConfig {
  const gammaNlUrlConfig: UrlConfig = {
    nl: {
      loyaltyProgram: `${baseConfig.SHOPFRONT_URL}/voordeelpas`,
      returnRequirements: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retour-en-terugbetalen#faq-1-5`,
      returnCosts: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retour-en-terugbetalen#faq-1-0`,
      returnFaqs: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retour-en-terugbetalen`,
      returnToShopFaqs: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retourneren#faq-1-4`,
      privacy: `${baseConfig.SHOPFRONT_URL}/klantenservice/veiligheid-privacy`,
      customerService: `${baseConfig.SHOPFRONT_URL}/klantenservice`,
      warranty: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/garantie`,
      store: `/bouwmarkten`,
    },
  }

  const gammaBeUrlConfig: UrlConfig = {
    nl: {
      loyaltyProgram: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie/gammapluskaart`,
      returnRequirements: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie`,
      returnCosts: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie`,
      returnFaqs: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie`,
      returnToShopFaqs: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie/ruilen-retourneren/omruilen-winkel`,
      privacy: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie/veiligheid-privacy`,
      customerService: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie`,
      warranty: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie/ruilen-retourneren/garantie`,
      store: `/nl/winkels-en-openingsuren`,
    },
    fr: {
      loyaltyProgram: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur/carte-gammaplus`,
      returnRequirements: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur`,
      returnCosts: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur`,
      returnFaqs: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur`,
      returnToShopFaqs: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur/echanges-retours/echange-magasin`,
      privacy: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur/securite-vie-privee`,
      customerService: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur`,
      warranty: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur/echanges-retours/garantie`,
      store: `/fr/magasins-et-heures-ouverture`,
    },
  }

  const karweiNlUrlConfig: UrlConfig = {
    nl: {
      loyaltyProgram: `${baseConfig.SHOPFRONT_URL}/clubkarwei`,
      returnRequirements: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retour-en-terugbetalen#faq-1-5`,
      returnCosts: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retour-en-terugbetalen#faq-1-0`,
      returnFaqs: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retour-en-terugbetalen`,
      returnToShopFaqs: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retourneren#faq-1-4`,
      privacy: `${baseConfig.SHOPFRONT_URL}/klantenservice/voorwaarden-veiligheid`,
      customerService: `${baseConfig.SHOPFRONT_URL}/klantenservice`,
      warranty: `${baseConfig.SHOPFRONT_URL}/nl/klantenservice/faq/garantie`,
      store: `/vestigingen`,
    },
  }

  switch (baseConfig.fullBrand) {
    case "gamma_nl":
      return gammaNlUrlConfig
    case "gamma_be":
      return gammaBeUrlConfig
    case "karwei_nl":
      return karweiNlUrlConfig
    default:
      return gammaNlUrlConfig
  }
}
