import type { LoyaltyToDiscount, LoyaltyToDiscountResponse } from "../types/LoyaltyCardStatus"
import {
  LoyaltyCardPointsToDiscountStatus,
  LoyaltyCardPointsToDiscountStatusResponse,
} from "../types/LoyaltyCardStatus"

import { mapMoneyResponse } from "~/services/mappers/mapMoneyResponse"

export function mapLoyaltyToDiscountResponse(
  response: LoyaltyToDiscountResponse,
  loyaltyCardNumber: string
): LoyaltyToDiscount {
  if (response.status === LoyaltyCardPointsToDiscountStatusResponse.SUCCESSFULLY_CONVERTED) {
    return {
      ...response,
      status: LoyaltyCardPointsToDiscountStatus.SUCCESSFULLY_CONVERTED,
      discountValue: mapMoneyResponse(response.discountValue),
    }
  }

  if (response.status === LoyaltyCardPointsToDiscountStatusResponse.CAN_CONVERT) {
    return {
      ...response,
      status: LoyaltyCardPointsToDiscountStatus.CAN_CONVERT,
      monetaryValue: mapMoneyResponse(response.monetaryValue),
      loyaltyCardNumber,
    }
  }

  return {
    ...response,
    status: mapLoyaltyStatusCode(response.status),
  }
}

function mapLoyaltyStatusCode(status: LoyaltyCardPointsToDiscountStatusResponse) {
  switch (status) {
    case LoyaltyCardPointsToDiscountStatusResponse.CREATE_DISCOUNT_CODE_FAILED:
      return LoyaltyCardPointsToDiscountStatus.CREATE_DISCOUNT_CODE_FAILED
    case LoyaltyCardPointsToDiscountStatusResponse.INSUFFICIENT_BALANCE:
      return LoyaltyCardPointsToDiscountStatus.INSUFFICIENT_BALANCE
    case LoyaltyCardPointsToDiscountStatusResponse.INVALID_RECAPTCHA:
      return LoyaltyCardPointsToDiscountStatus.INVALID_RECAPTCHA
    case LoyaltyCardPointsToDiscountStatusResponse.NEW:
      return LoyaltyCardPointsToDiscountStatus.NEW
    case LoyaltyCardPointsToDiscountStatusResponse.UNAUTHORIZED:
      return LoyaltyCardPointsToDiscountStatus.UNAUTHORIZED
    case LoyaltyCardPointsToDiscountStatusResponse.LOYALTY_CARD_REGISTERED:
      return LoyaltyCardPointsToDiscountStatus.LOYALTY_CARD_REGISTERED
    default:
      return LoyaltyCardPointsToDiscountStatus.FAILED_UNKNOWN
  }
}
