import { forwardRef, type SVGProps } from "react";

export interface GearIconProps extends SVGProps<SVGSVGElement> {}

export const Gear = forwardRef<SVGSVGElement, GearIconProps>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_9199_1345)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.418 2.75164C12.9408 1.41442 11.0553 1.41807 10.5822 2.75088C9.98598 4.43039 8.06635 5.22111 6.46098 4.45708C5.18387 3.84926 3.84925 5.18386 4.45708 6.46097C5.22024 8.06906 4.43074 9.98538 2.75164 10.582C1.41442 11.0592 1.41807 12.9447 2.75088 13.4178C4.43039 14.014 5.22111 15.9336 4.45708 17.539C3.84926 18.8161 5.18386 20.1507 6.46097 19.5429C8.06904 18.7798 9.98534 19.5692 10.5819 21.2483C11.0591 22.5856 12.9447 22.5819 13.4178 21.2491C14.014 19.5696 15.9337 18.7789 17.539 19.5429C18.8161 20.1507 20.1507 18.8161 19.5429 17.539C18.7798 15.931 19.5692 14.0147 21.2483 13.4181C22.5856 12.9409 22.5819 11.0553 21.2491 10.5822C19.5696 9.98598 18.7789 8.06635 19.5429 6.46098C20.1507 5.18387 18.8161 3.84925 17.539 4.45708C15.9309 5.22024 14.0146 4.43074 13.418 2.75164ZM9.16863 2.24912C10.1153 -0.417969 13.8814 -0.414423 14.831 2.24805L14.8314 2.24908C15.1292 3.08813 16.0868 3.48566 16.8948 3.10247C19.4469 1.88821 22.112 4.55347 20.8974 7.10559C20.5146 7.90975 20.9116 8.87066 21.7509 9.16863C24.418 10.1153 24.4144 13.8814 21.7519 14.831L21.7509 14.8314C20.9119 15.1292 20.5143 16.0868 20.8975 16.8948C22.1118 19.4469 19.4465 22.112 16.8944 20.8974C16.0903 20.5146 15.1293 20.9116 14.8314 21.7509C13.8847 24.418 10.1186 24.4144 9.16901 21.7519L9.16865 21.7509C8.87077 20.9119 7.91319 20.5143 7.10521 20.8975C4.55308 22.1118 1.88801 19.4465 3.10265 16.8944C3.48537 16.0902 3.08836 15.1293 2.24912 14.8314C-0.417969 13.8847 -0.414423 10.1186 2.24805 9.16901L2.24908 9.16865C3.08813 8.87077 3.48566 7.91319 3.10247 7.10521M9.16863 2.24912C8.87066 3.08836 7.90975 3.48537 7.10558 3.10265C4.55359 1.88807 1.88865 4.5532 3.10247 7.10521"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_9199_1345">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

(Gear as React.FC).displayName = "Gear";
