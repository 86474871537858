import { createHttpClient } from "@intergamma/account"

import axios from "axios"
import type { Store, StoreResponse } from "./types"

export const PAGE_SIZE = 10

export function createStoresService(CHECKOUT_URL: string, API_GATEWAY_URL: string) {
  const checkoutClient = createHttpClient(`${CHECKOUT_URL}/api/store`, {
    withCredentials: true,
  })

  const API_GATEWAY_STORE_API_URL = `${API_GATEWAY_URL}/store/2/stores/closest`

  return {
    searchStores(query: string, limit = 8): Promise<Store[] | null> {
      return checkoutClient.get(`search/${encodeURI(query)}?limit=${limit}`)
    },

    // If we use the createHttpClient function for the API gateway we get CORS errors, so we use axios directly.
    async searchStoresByGeolocation(latitude: number, longitude: number): Promise<StoreResponse[] | null> {
      const { data } = await axios.get(
        `${API_GATEWAY_STORE_API_URL}/geopoint?latitude=${latitude}&longitude=${longitude}&rangeInKm=999&limit=10`
      )

      return data
    },

    async searchStoresByQuery(query: string): Promise<StoreResponse[] | null> {
      const { data } = await axios.get(`${API_GATEWAY_STORE_API_URL}/location?query=${query}&rangeInKm=999&limit=10`)

      return data
    },
  }
}
