import { createHttpClient } from "@intergamma/account"

import type { OptIns, OptInsRequestParams, OptInsResponse, UpdateOptInsBody } from "./types/OptIns"

export function createOptInsService(MYACCOUNT_URL: string) {
  const optInsClient = createHttpClient(`${MYACCOUNT_URL}/api`, {
    withCredentials: true,
  })

  return {
    getOptIns({ hash }: OptInsRequestParams): Promise<OptIns> {
      const url = hash ? `/optins?hash=${encodeURIComponent(hash)}` : "/optins"

      return optInsClient.get<OptInsResponse>(url)
    },
    updateOptIns(body: UpdateOptInsBody, { hash }: OptInsRequestParams): Promise<OptIns> {
      const url = hash ? `/optins?hash=${encodeURIComponent(hash)}` : "/optins"

      return optInsClient.post<OptInsResponse>(url, body)
    },
  }
}
