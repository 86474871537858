import { Gear } from "@intergamma/ui/icons/Gear"
import { LoyaltyCard } from "@intergamma/ui/icons/LoyaltyCard"
import { MeasuringTape } from "@intergamma/ui/icons/MeasuringTape"
import { Package } from "@intergamma/ui/icons/Package"
import { Return } from "@intergamma/ui/icons/Return"
import { Store } from "@intergamma/ui/icons/Store"
import { User } from "@intergamma/ui/icons/User"
import { useTranslation } from "react-i18next"
import { useSavedConfigurations } from "~/features/saved-configurations/hooks/useSavedConfigurations"
import type { NavigationItemProps } from "../components/NavigationItem"

export function useNavigationItems(): (NavigationItemProps | null)[] {
  const { t } = useTranslation(["urls", "common", "logged-in-navigation"])
  const { data } = useSavedConfigurations()
  const savedConfigurations = data ?? []

  return [
    {
      title: t("common:MyAccount"),
      url: t("urls:/myaccount"),
      Icon: Store,
    },
    {
      title: t("logged-in-navigation:purchases"),
      url: t("urls:/purchases"),
      Icon: Package,
    },
    savedConfigurations.length > 0
      ? {
          title: t("logged-in-navigation:savedConfigurations"),
          url: t("urls:/saved-configurations"),
          Icon: MeasuringTape,
        }
      : null,
    {
      // This item is temporary until we move to the new dashboard/header.
      title: t("common:loyaltyName"),
      url: t("urls:/loyalty"),
      Icon: LoyaltyCard,
    },
    {
      title: t("logged-in-navigation:return"),
      url: t("urls:/request-return"),
      Icon: Return,
    },
    {
      title: t("logged-in-navigation:profile"),
      url: t("urls:/profile/personal-information"),
      Icon: User,
    },
    {
      title: t("logged-in-navigation:settings"),
      url: t("urls:/profile/newsletter"),
      Icon: Gear,
    },
  ]
}
