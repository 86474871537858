import { forwardRef, type SVGProps } from "react";

export interface ReturnIconProps extends SVGProps<SVGSVGElement> {}

export const Return = forwardRef<SVGSVGElement, ReturnIconProps>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...props}
    >
      <path
        d="M20.2501 5.5C20.2501 5.08579 19.9143 4.75 19.5001 4.75C19.0859 4.75 18.7501 5.08579 18.7501 5.5V10C18.7501 11.7949 17.295 13.25 15.5001 13.25H6.31077L10.0304 9.53033C10.3233 9.23744 10.3233 8.76256 10.0304 8.46967C9.73755 8.17678 9.26268 8.17678 8.96978 8.46967L3.43945 14L8.96978 19.5303C9.26268 19.8232 9.73755 19.8232 10.0304 19.5303C10.3233 19.2374 10.3233 18.7626 10.0304 18.4697L6.31077 14.75H15.5001C18.1235 14.75 20.2501 12.6234 20.2501 10V5.5Z"
        fill="currentColor"
      />
    </svg>
  ),
);

(Return as React.FC).displayName = "Return";
