import type { ReactNode } from "react"
import { createContext, useContext } from "react"

import i18next from "i18next"

import type { ApiKeysConfig } from "./ApiKeysConfig"
import type { BaseConfig } from "./BaseConfig"
import type { DomainConfig } from "./DomainConfig"
import type { LocaleConfig } from "./LocaleConfig"
import type { UrlConfig } from "./UrlConfig"

export interface MyAccountConfig {
  baseConfig: BaseConfig
  apiKeys: ApiKeysConfig
  locales: LocaleConfig
  redirects: {
    nl: Record<string, string>
    fr?: Record<string, string>
  }
  domainConfig: DomainConfig
  urls: UrlConfig
  experimentCookieTtlInDays: number
}

const MyAccountConfigContext = createContext<MyAccountConfig | null>(null)

interface ConfigProviderProps {
  children: ReactNode
  config: MyAccountConfig
}

export function MyAccountConfigProvider({ children, config }: ConfigProviderProps) {
  if (!config) {
    return null
  }

  i18next.on("languageChanged", (lng) => {
    document.documentElement.setAttribute("lang", lng)
  })

  return <MyAccountConfigContext.Provider value={config}>{children}</MyAccountConfigContext.Provider>
}

export function useMyAccountConfig() {
  const context = useContext(MyAccountConfigContext)

  if (context === null) {
    throw new Error("useMyAccountConfig must be used with a MyAccountConfigProvider ")
  }

  return context
}

export function useBaseConfig() {
  const { baseConfig } = useMyAccountConfig()

  return baseConfig
}

export function useApiKeysConfig() {
  const { apiKeys } = useMyAccountConfig()

  return apiKeys
}
