import packageJson from "../../package.json"

import type { Brands } from "./Brands"

export interface BaseConfig {
  brand: "gamma" | "karwei"
  country: "nl" | "be"
  fullBrand: Brands
  isLocal: boolean
  isDevelopment: boolean
  isAcceptance: boolean
  isProduction: boolean
  baseDomain: string
  CHECKOUT_URL: string
  MYACCOUNT_URL: string
  MYACCOUNT_FRONTEND: string
  SHOPFRONT_URL: string
  STATIC_URL: string
  cookiePrefix: string
  cookieDomain: string
  algoliaApplicationId: string
  version: string
  API_GATEWAY_URL: string
}

export function getBaseConfig(domain: string): BaseConfig {
  const env = domain.indexOf("acceptatie") >= 0 ? "acc" : "www"
  const brand = /karwei\.nl$/.test(domain) ? "karwei" : "gamma"
  const country = /gamma\.be$/.test(domain) ? "be" : "nl"
  const urlPrefix = env === "acc" ? "acceptatie." : ""
  const baseDomain = `${urlPrefix}${brand}.${country}`
  const isLocal = domain.indexOf("local") >= 0

  function getMyAccountUrl() {
    if (isLocal) {
      return `/${brand}/${country}/${env === "acc" ? "acceptatie" : "production"}/myaccount`
    }

    return `https://mijn.${baseDomain}`
  }

  function getShopfrontUrl() {
    if (isLocal) {
      return `/${brand}/${country}/${env === "acc" ? "acceptatie" : "production"}/shopfront`
    }

    return `https://www.${baseDomain}`
  }

  function getCheckoutUrl() {
    if (isLocal) {
      return `/${brand}/${country}/${env === "acc" ? "acceptatie" : "production"}/checkout`
    }

    return `https://kassa.${baseDomain}`
  }

  function getApiGatewayUrl() {
    if (isLocal) {
      return `/${brand}/${country}/${env === "acc" ? "acceptatie" : "production"}/api-gateway`
    }

    return `https://api.${baseDomain}`
  }

  const API_GATEWAY_URL = getApiGatewayUrl()
  const CHECKOUT_URL = getCheckoutUrl()
  const MYACCOUNT_URL = getMyAccountUrl()
  const MYACCOUNT_FRONTEND = getMyAccountUrl()
  const SHOPFRONT_URL = getShopfrontUrl()

  return {
    brand,
    country,
    fullBrand: `${brand}_${country}` as Brands,
    isLocal,
    isDevelopment: import.meta.env.DEV,
    isAcceptance: env === "acc",
    isProduction: env === "www",
    baseDomain,
    CHECKOUT_URL,
    MYACCOUNT_URL,
    MYACCOUNT_FRONTEND,
    SHOPFRONT_URL,
    STATIC_URL: `https://static.${baseDomain}`,
    cookiePrefix: `${env}-${brand}-${country}`,
    cookieDomain: env === "acc" ? baseDomain : `.${baseDomain}`,
    algoliaApplicationId: "4CI6R68JTI",
    version: packageJson.version,
    API_GATEWAY_URL,
  }
}
