import { useState } from "react"

import { useConfig } from "@intergamma/config"
import { DialogBottomSheet, DialogExtended } from "@intergamma/dialog"
import { Button } from "@intergamma/ui/button"

interface ConfirmDialogProps {
  trigger: React.ReactNode
  title: string
  message: string
  buttonLabels: {
    confirm: string
    cancel: string
  }
  action: () => void
  hasDarkPattern?: boolean
}

export function ConfirmDialog({
  trigger,
  title,
  message,
  action,
  buttonLabels,
  hasDarkPattern = true,
}: ConfirmDialogProps) {
  const [open, setOpen] = useState(false)
  const { isMobile } = useConfig()

  const submit = () => {
    action()
    setOpen(false)
  }

  const Dialog = isMobile ? DialogBottomSheet : DialogExtended

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)} trigger={trigger} dialogTitle={title}>
      <div className="p-4" data-testid="confirm-dialog">
        <h3 className="whitespace-pre-wrap py-6 text-200 font-bold text-brand-primary">{title}</h3>
        <p>{message}</p>
        <div className="mt-6 grid grid-flow-col grid-cols-2 gap-2">
          <Button
            onClick={submit}
            variant={hasDarkPattern ? "secondary" : "primary"}
            data-testid="confirm-button"
            className={!hasDarkPattern ? "order-1" : undefined}
          >
            {buttonLabels.confirm}
          </Button>
          <Button
            onClick={() => setOpen(false)}
            variant={!hasDarkPattern ? "secondary" : "primary"}
            className={!hasDarkPattern ? "-order-1" : undefined}
          >
            {buttonLabels.cancel}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
