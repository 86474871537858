import { Correct } from "@intergamma/icons"

import { useMutation } from "@tanstack/react-query"
import { isAxiosError } from "axios"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

import { useBaseApiService } from "~/features/shared/ServicesContext"

import { log } from "~/helpers/tracking/datadog-logging"
import { TranslatedError } from "~/helpers/TranslatedError"

import type { VerifyRequest } from "~/services/base-api/generated"

export function useVerifyChange() {
  const baseApiService = useBaseApiService()

  const { t } = useTranslation(["change-email", "errors", "register"])

  function verifyEmail(body: VerifyRequest) {
    return baseApiService.verifyEmail(body).catch((error) => {
      log.error("Email verification failed", {}, error)

      if (isAxiosError(error)) {
        const statusCode = error.response?.status
        const attemptsLeft = error.response?.data?.attemptsLeft

        if (statusCode === 403) {
          if (attemptsLeft === 0) {
            throw new TranslatedError(t("register:tooManyWrongAttempts"))
          } else {
            throw new TranslatedError(t("register:errorNotValidCode"))
          }
        }
      }

      throw new TranslatedError(`${t("change-email:errorMessage")} ${t("errors:ContactCustomerSupport")}`)
    })
  }

  return useMutation({
    mutationFn: verifyEmail,
    throwOnError: false,
    onSuccess: () => {
      toast.success(t("change-email:successfulSave"), {
        icon: Correct,
      })
    },
  })
}
