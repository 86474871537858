import type { Config } from "@intergamma/config"
import type { Theme } from "@intergamma/theme"
import { gamma_be, gamma_nl, karwei_nl } from "@intergamma/theme"

import { getApiKeysConfig } from "./ApiKeysConfig"
import type { BaseConfig } from "./BaseConfig"
import { getLocaleConfig } from "./LocaleConfig"
import { gammaNlRedirects } from "./routes/gammaNlRedirects"

export function getSkin(baseConfig: BaseConfig): Skin {
  const skins = {
    gamma_nl: {
      config: {
        ...baseConfig,
        ...getApiKeysConfig(baseConfig),
        ...getLocaleConfig("gamma_nl"),
        redirects: {
          nl: gammaNlRedirects,
        },
      },
      getTheme: () => gamma_nl,
    },
    gamma_be: {
      config: {
        ...baseConfig,
        ...getApiKeysConfig(baseConfig),
        ...getLocaleConfig("gamma_be"),
      },
      getTheme: () => gamma_be,
    },
    karwei_nl: {
      config: {
        ...baseConfig,
        ...getApiKeysConfig(baseConfig),
        ...getLocaleConfig("karwei_nl"),
      },
      getTheme: () => karwei_nl,
    },
  }

  return skins[baseConfig.fullBrand]
}

export type Skin = {
  config: Config
  getTheme: () => Theme
}

export type Skins = {
  gamma_nl: Skin
  gamma_be: Skin
  karwei_nl: Skin
}
