import { createHttpClient } from "@intergamma/account"

import { withGatewayCsrfToken } from "../helpers/withGatewayCsrfToken"

export function createGatewayService(MYACCOUNT_URL: string) {
  const gatewayClient = withGatewayCsrfToken(
    MYACCOUNT_URL,
    createHttpClient(`${MYACCOUNT_URL}/gateway`, {
      withCredentials: true,
    })
  )

  return {
    logout(): Promise<void> {
      return gatewayClient.post<void>("/logout")
    },
    removeAccount(): Promise<void> {
      return gatewayClient.delete("/account")
    },
  }
}
