import type { HandlerType, StatusType } from "@datadog/browser-logs"

import type { BaseConfig } from "./BaseConfig"

export interface ApiKeysConfig {
  suggestionApiKey: string
  reCaptchaSiteKey: string
  dataDog: {
    clientKey: string
    logDestination: HandlerType
    logLevel: StatusType
    rumClientKey: string
    rumApplicationId: string
  }
}

export function getApiKeysConfig(config: BaseConfig) {
  const gammaNlApiKeys: ApiKeysConfig = {
    suggestionApiKey: "c2c01a73654f317715999f4da06aaaec",
    reCaptchaSiteKey: config.isProduction
      ? "6Lc7TZ8qAAAAAFRiWDTG7AI_4XVvGxP3qcjH9Q_p"
      : "6LeqSp8qAAAAAG2BlOyYeo0efTPNw1l1MKaXLDic",
    dataDog: {
      clientKey: "pubd83b387b1528d5cd6ab587f2c3582686",
      logDestination: config.isLocal ? "console" : "http",
      logLevel: "info",
      rumApplicationId: config.isProduction
        ? "a15dc67f-5bfa-43db-99ac-f4ef38101953"
        : "6668c0f4-3c90-4496-8486-025f41bf505c",
      rumClientKey: config.isProduction ? "pub8f8e1a27f8a2ebd845d810fe0934c267" : "pubce6e4f2a8733dd65661d9e9d64d47dc8",
    },
  }

  const gammaBeApiKeys: ApiKeysConfig = {
    suggestionApiKey: "4357fc250fdcb2aedd382b560d7715a4",
    reCaptchaSiteKey: config.isProduction
      ? "6LdqTZ8qAAAAAB8uvdDqib1isfjM0yQhuc80L1ql"
      : "6Ld1TJ8qAAAAAIiA6b7pj7eCnFdOa8O4ZLv4tPUv",
    dataDog: {
      clientKey: "pubd83b387b1528d5cd6ab587f2c3582686",
      logDestination: config.isLocal ? "console" : "http",
      logLevel: "info",
      rumApplicationId: config.isProduction
        ? "90667f6c-c500-4552-9430-df68b21afeda"
        : "6668c0f4-3c90-4496-8486-025f41bf505c",
      rumClientKey: config.isProduction ? "pub63ba49580d72f7075965cd99a2eb6986" : "pubce6e4f2a8733dd65661d9e9d64d47dc8",
    },
  }

  const karweiNlApiKeys: ApiKeysConfig = {
    suggestionApiKey: "3575eb1efa184932d3d9859e646e920f",
    reCaptchaSiteKey: config.isProduction
      ? "6Ld6TZ8qAAAAAAGbc0nrGzct-IiUuLSf5XVW6HhT"
      : "6Le8TJ8qAAAAAACVWG_XlA_vilo2q4itkV0PmkSC",
    dataDog: {
      clientKey: "pubd83b387b1528d5cd6ab587f2c3582686",
      logDestination: config.isLocal ? "console" : "http",
      logLevel: "info",
      rumApplicationId: config.isProduction
        ? "dd437ba6-3ce0-4fc5-a60e-34025bf9878a"
        : "6668c0f4-3c90-4496-8486-025f41bf505c",
      rumClientKey: config.isProduction ? "pub4af38f5adc23a622fe21f059e70de1f5" : "pubce6e4f2a8733dd65661d9e9d64d47dc8",
    },
  }

  switch (config.fullBrand) {
    case "gamma_nl":
      return gammaNlApiKeys
    case "gamma_be":
      return gammaBeApiKeys
    case "karwei_nl":
      return karweiNlApiKeys
    default:
      return gammaNlApiKeys
  }
}
