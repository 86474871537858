import { DialogContent, DialogRoot } from "@intergamma/dialog"
import { Cross } from "@intergamma/ui/icons/Cross"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"

import { Heading } from "~/components/Heading"

import { RemoveAccountForm } from "./components/RemoveAccountForm"
import { useRemoveAccount } from "./hooks/useRemoveAccount"

export function RemoveAccount() {
  const { t } = useTranslation(["profile", "urls"])
  const { mutate: onSubmit, error, isPending } = useRemoveAccount()
  const navigate = useNavigate()

  const onClose = () => navigate(t("urls:/profile"))

  return (
    <DialogRoot open>
      <DialogContent className="grid max-w-[690px] gap-4 p-6">
        <div className="flex items-center justify-between">
          <Heading type="h3">{t("profile:removeAccountTitle")}</Heading>
          <button type="button" onClick={onClose}>
            <Cross className="text-functional-primary-600" />
          </button>
        </div>
        <RemoveAccountForm
          errorMessage={error?.message ?? null}
          isLoading={isPending}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </DialogContent>
    </DialogRoot>
  )
}
