import { dispatchAdobeEvent } from "@intergamma/adobe-tracking"

import { useQueryClient } from "@tanstack/react-query"
import { signOut } from "aws-amplify/auth"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"

import { useGatewayService } from "~/features/shared/ServicesContext"

import { useRumEvent } from "~/hooks/useRumEvent"

export function useLogout() {
  const queryClient = useQueryClient()
  const gatewayService = useGatewayService()

  const { logoutEvent } = useRumEvent()
  const { t } = useTranslation("urls")
  const navigate = useNavigate()

  const logout = () =>
    new Promise((resolve) => {
      signOut()
        // As long as we support the session cookie we also have to logout through the old method to remove the session cookie.
        .then(() => gatewayService.logout())
        .then(() => {
          queryClient.invalidateQueries()

          navigate(t("/login"))

          dispatchAdobeEvent({ type: "logout" })
          logoutEvent()

          return resolve
        })
    })

  return {
    logout,
  }
}
