import type { Purchase, PurchaseResponse, PurchaseStatus } from "../types/Purchase"
import { PurchaseState, PurchaseStatusType, PurchaseStep, PurchaseType } from "../types/Purchase"

import { getDateTime } from "./helpers"

import { mapLocalDateResponse } from "~/services/mappers/mapLocalDateResponse"

export const getPurchaseType = (type: string): PurchaseType => {
  switch (type) {
    case "ORDER":
      return PurchaseType.ORDER
    case "RECEIPT":
      return PurchaseType.RECEIPT
    default:
      throw new Error(`Unknown purchase type: ${type}`)
  }
}

export const getPurchaseStatus = (state: string, type: PurchaseType): PurchaseStatus => {
  const isOrder = type === PurchaseType.ORDER

  switch (state) {
    case "COMPLETED":
      return {
        state: isOrder ? PurchaseState.ORDER_DELIVERED : PurchaseState.STORE_BOUGHT,
        type: PurchaseStatusType.SUCCESS,
        step: PurchaseStep.READY,
      }
    case "REGISTERED_FOR_SHIPMENT":
      return {
        state: PurchaseState.ORDER_READY_FOR_SHIPPING,
        type: PurchaseStatusType.PENDING,
        step: PurchaseStep.SHIPPING,
      }
    case "SHIPPING":
    case "SHIPPED":
    case "TO_BE_REROUTED":
      return {
        state: PurchaseState.ORDER_SHIPPED,
        type: PurchaseStatusType.PENDING,
        step: PurchaseStep.SHIPPING,
      }
    case "READY_AT_PICKUP_LOCATION":
      return {
        state: PurchaseState.ORDER_READY_FOR_PICKUP,
        type: PurchaseStatusType.SUCCESS,
        step: PurchaseStep.SHIPPING,
      }
    case "PICKED_UP":
      return {
        state: PurchaseState.ORDER_PICKED_UP,
        type: PurchaseStatusType.SUCCESS,
        step: PurchaseStep.READY,
      }
    case "DELIVERED":
      return {
        state: PurchaseState.ORDER_DELIVERED,
        type: PurchaseStatusType.SUCCESS,
        step: PurchaseStep.READY,
      }
    case "CANCELLED":
    case "TO_BE_CANCELLED":
      return {
        state: PurchaseState.CANCELLED,
        type: PurchaseStatusType.ERROR,
        step: PurchaseStep.READY,
      }
    case "RETURNED":
      return {
        state: PurchaseState.RETURN_BROUGHT_TO_STORE,
        type: PurchaseStatusType.PENDING,
        step: PurchaseStep.READY,
      }
    case "REFUNDED":
      return {
        state: PurchaseState.RETURN_REFUNDED,
        type: PurchaseStatusType.SUCCESS,
        step: PurchaseStep.READY,
      }
    case "REPLACED":
      return {
        state: PurchaseState.REPLACED,
        type: PurchaseStatusType.SUCCESS,
        step: PurchaseStep.READY,
      }
    case "REFUSED":
      return {
        state: PurchaseState.DELIVERY_REFUSED,
        type: PurchaseStatusType.WARNING,
        step: PurchaseStep.READY,
      }
    case "MISSING_OR_REJECTED":
      return {
        state: PurchaseState.DELIVERY_LOST,
        type: PurchaseStatusType.WARNING,
        step: PurchaseStep.PREPARING,
      }
    case "UNKNOWN":
      return {
        state: PurchaseState.UNKNOWN,
        type: PurchaseStatusType.PENDING,
        step: PurchaseStep.STARTED,
      }
    default:
      return {
        state: PurchaseState.IN_PROGRESS,
        type: PurchaseStatusType.PENDING,
        step: PurchaseStep.STARTED,
      }
  }
}

export const mapPurchasesResponse = (purchase: PurchaseResponse): Purchase => {
  const purchaseType = getPurchaseType(purchase.type)

  return {
    purchaseId: purchase.purchaseId,
    type: purchaseType,
    purchasedOn: {
      date: mapLocalDateResponse(purchase.purchasedOn),
      time: null,
    },
    status: getPurchaseStatus(purchase.state, purchaseType),
    items: purchase.items.map((item) => ({
      code: item.code,
      imageUrl: item.imageUrl,
      imageWidth: item.imageWidth,
      name: item.name,
      url: item.url,
      pickupCode: item.pickupCode,
      delivery: item.deliveryWindow
        ? {
            start: item.deliveryWindow.start && getDateTime(item.deliveryWindow.start),

            end: item.deliveryWindow.end && getDateTime(item.deliveryWindow.end),
          }
        : null,
      status: getPurchaseStatus(item.state, purchaseType),
      tracking: item.tracking,
    })),
  }
}
