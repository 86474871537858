import { createHttpClient } from "@intergamma/account"

import { checkLoyaltyPointsStatus } from "./mappers/checkLoyaltyPointsStatus"
import { mapLoyaltyToDiscountResponse } from "./mappers/mapLoyaltyToDiscountResponse"
import type {
  LoyaltyBalanceCheckBody,
  LoyaltyConvertBody,
  LoyaltyToDiscount,
  LoyaltyToDiscountResponse,
} from "./types/LoyaltyCardStatus"

export function createLoyaltyToDiscountService(MYACCOUNT_URL: string) {
  const baseClient = createHttpClient(`${MYACCOUNT_URL}/api/loyaltypoints-to-discount`, {
    withCredentials: true,
  })

  return {
    checkLoyaltyBalance({ loyaltyCardNumber, ...payload }: LoyaltyBalanceCheckBody): Promise<LoyaltyToDiscount> {
      return baseClient
        .post<LoyaltyToDiscountResponse>(`/balance/${loyaltyCardNumber}`, payload)
        .then((response) => checkLoyaltyPointsStatus(response))
        .then((response) => mapLoyaltyToDiscountResponse(response, loyaltyCardNumber))
    },
    convertLoyaltyCardToDiscountCode({
      loyaltyCardNumber,
      ...payload
    }: LoyaltyConvertBody): Promise<LoyaltyToDiscount> {
      return baseClient
        .post<LoyaltyToDiscountResponse>(`/to-discount/${loyaltyCardNumber}`, payload)
        .then((response) => checkLoyaltyPointsStatus(response))
        .then((response) => mapLoyaltyToDiscountResponse(response, loyaltyCardNumber))
    },
  }
}
