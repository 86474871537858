import { memo } from "react"

import { Container } from "@intergamma/container"
import { media } from "@intergamma/theme"

import { Outlet } from "react-router"
import { styled } from "styled-components"

function RouteLayoutView({ whiteBg = false }: { whiteBg?: boolean }) {
  return (
    <PageContainer $whiteBackground={whiteBg}>
      <StyledInnerContainer>
        <Outlet />
      </StyledInnerContainer>
    </PageContainer>
  )
}

const PageContainer = styled.div<{ $whiteBackground: boolean }>`
  background: ${(props) => (props.$whiteBackground ? "#fff" : "#f5f5f5")};
  min-height: 80vh;
`

const StyledInnerContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${media.md`
    flex-direction: row;
  `}
`

export const Layout = memo(RouteLayoutView)
