import { Suspense, lazy } from "react"

import "./main.css"

import { configureAuth } from "@intergamma/account"
import { dispatchAdobeEvent, waitForGALoad } from "@intergamma/adobe-tracking"
import { isSyntheticTest } from "@intergamma/common/synthetic"
import { ConfigProvider } from "@intergamma/config"
import { experiments } from "@intergamma/experiments"
import { GlobalToastStyling, IntergammaToastContainer } from "@intergamma/global-styling"
import { IntergammaTheme } from "@intergamma/theme-next"

import { datadogLogs } from "@datadog/browser-logs"
import { datadogRum } from "@datadog/browser-rum"
import { QueryClientProvider, QueryErrorResetBoundary } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { createRoot } from "react-dom/client"
import { ErrorBoundary } from "react-error-boundary"
import { BrowserRouter } from "react-router"

import { App } from "./App"
import { DeviceContextProvider } from "./components/Device"
import { ErrorComponent } from "./components/ErrorComponent"
import { getApiKeysConfig } from "./config/ApiKeysConfig"
import { getBaseConfig } from "./config/BaseConfig"
import { DataDogLogging } from "./config/DataDog"
import { getDomainConfig } from "./config/DomainConfig"
import { getLocaleConfig } from "./config/LocaleConfig"
import { MyAccountConfigProvider } from "./config/MyAccountConfigContext"
import { getRedirectsConfig } from "./config/RedirectsConfig"
import { getUrlConfig } from "./config/UrlConfig"
import { formula } from "./config/formula"
import { queryClient } from "./config/query"
import { getSkin } from "./config/skin"
import { RegisterQueryClientErrorHandler } from "./features/shared/RegisterQueryClientErrorHandler"
import { ServicesProvider } from "./features/shared/ServicesContext"
import configureI18n from "./i18n"

const DevtoolsBar = lazy(() =>
  import("./components/DevtoolsBar").then((module) => ({
    default: module.DevtoolsBar,
  }))
)

async function prepare() {
  if (import.meta.env.DEV && import.meta.env.VITE_ENABLE_MSW === "true") {
    const { worker } = await import("./mocks/browser")

    return worker.start({
      onUnhandledRequest: "bypass",
    })
  }

  return null
}

const domainMatch = document.location.href.match(/\/\/([^:/]+)/)
const domainFromUrl = domainMatch ? domainMatch[1] : ""

const baseConfig = getBaseConfig(domainFromUrl)
const selectedSkin = getSkin(baseConfig)
const { config, getTheme } = selectedSkin
const theme = getTheme()

configureI18n({ theme, config })

// Send abtest event for already bucketed experiments
Object.entries(experiments.active).forEach(([name, experiment]) => {
  dispatchAdobeEvent({
    type: "abtest",
    data: {
      abtest_name: name,
      abtest_variant: experiment.variant ?? "do-not-track",
      domain_and_index: experiment.domain_and_index,
    },
  })
})

datadogLogs.setGlobalContextProperty("experiments", experiments.active)
datadogRum.setGlobalContextProperty("experiments", experiments.active)

experiments.configure({
  dev: baseConfig.isLocal || isSyntheticTest,
  formula,
  async onCoinflip(name, experiment, variant) {
    datadogLogs.setGlobalContextProperty("experiments", experiments.active)
    datadogRum.setGlobalContextProperty("experiments", experiments.active)

    await waitForGALoad()

    dispatchAdobeEvent({
      type: "abtest",
      data: {
        abtest_name: name,
        abtest_variant: typeof variant === "string" ? variant : "do-not-track",
        domain_and_index: experiment.domain_and_index,
      },
    })
  },
})

if (!baseConfig.isDevelopment && "serviceWorker" in navigator) {
  navigator.serviceWorker.register("/my/sw.js")
}

// Set theme
document.documentElement.classList.add(theme.name === "karwei_nl" ? "theme-karwei" : "theme-gamma")

configureAuth(baseConfig.fullBrand)

prepare().then(() => {
  const container = document.getElementById("root")!
  const root = createRoot(container!)

  root.render(
    <Suspense fallback={<div />}>
      <MyAccountConfigProvider
        config={{
          baseConfig,
          apiKeys: getApiKeysConfig(baseConfig),
          locales: getLocaleConfig(baseConfig.fullBrand),
          redirects: getRedirectsConfig(baseConfig.fullBrand),
          domainConfig: getDomainConfig(baseConfig.fullBrand),
          urls: getUrlConfig(baseConfig),
          experimentCookieTtlInDays: 365,
        }}
      >
        <ConfigProvider config={config}>
          <DeviceContextProvider>
            <DataDogLogging>
              <IntergammaTheme formula={baseConfig.fullBrand}>
                <ServicesProvider>
                  <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                      <QueryErrorResetBoundary>
                        {({ reset }) => (
                          <ErrorBoundary onReset={reset} FallbackComponent={(props) => <ErrorComponent {...props} />}>
                            <IntergammaToastContainer />
                            <GlobalToastStyling />
                            {!baseConfig.isProduction && <DevtoolsBar />}
                            <RegisterQueryClientErrorHandler>
                              <App />
                            </RegisterQueryClientErrorHandler>
                          </ErrorBoundary>
                        )}
                      </QueryErrorResetBoundary>
                    </BrowserRouter>
                    <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
                  </QueryClientProvider>
                </ServicesProvider>
              </IntergammaTheme>
            </DataDogLogging>
          </DeviceContextProvider>
        </ConfigProvider>
      </MyAccountConfigProvider>
    </Suspense>
  )
})
