import type { DateTime, DeliveryWindow, DeliveryWindowResponse } from "../types/Purchase"
import { PurchaseState } from "../types/Purchase"

import { mapLocalDateResponse, mapLocalDateTimeResponse } from "~/services/mappers/mapLocalDateResponse"
import { mapTimeResponse } from "~/services/mappers/mapTimeResponse"
import type { LocalDateResponse } from "~/services/types/LocalDateResponse"

export const getDateTime = (date: LocalDateResponse): DateTime | null => {
  const timeArray = date.slice(3, 5)
  const isValidTime = timeArray.some((time) => typeof time !== "undefined")

  if (date) {
    return {
      date: mapLocalDateTimeResponse(date),
      time: isValidTime ? mapTimeResponse(timeArray as number[]) : null,
    }
  }

  return null
}

export const getDuration = (pastDate: LocalDateResponse, futureDate: LocalDateResponse) =>
  Math.round(
    (mapLocalDateResponse(futureDate).valueOf() - mapLocalDateResponse(pastDate).valueOf()) / (1000 * 60 * 60 * 24)
  )

const ReviewableStates: PurchaseState[] = [
  PurchaseState.COMPLETED,
  PurchaseState.ORDER_DELIVERED,
  PurchaseState.STORE_BOUGHT,
  PurchaseState.RETURN_BROUGHT_TO_STORE,
  PurchaseState.RETURN_REFUNDED,
  PurchaseState.RETURN_REGISTERED,
  PurchaseState.RETURN_SHIPPED,
]

export function purchaseItemIsReviewable(state: PurchaseState) {
  return ReviewableStates.includes(state)
}

export const getDeliveryWindow = (deliveryWindow: DeliveryWindowResponse): DeliveryWindow | null => {
  const window = {
    start: deliveryWindow.start && getDateTime(deliveryWindow.start),
    end: deliveryWindow.end && getDateTime(deliveryWindow.end),
  }

  if (window.start || window.end) {
    return window
  }

  return null
}
