import { cn } from "@intergamma/common/cn"
import { DialogTrigger } from "@intergamma/dialog"
import { ChevronRight } from "@intergamma/ui/icons/ChevronRight"
import type { SVGProps } from "react"
import { NavLink } from "react-router"

export interface NavigationItemProps {
  url?: string
  title: string
  Icon: React.FC<SVGProps<SVGSVGElement>>
}

export function NavigationItem({ url, title, Icon }: NavigationItemProps) {
  const linkClassNames = "flex cursor-pointer flex-row px-4 py-3 leading-7 w-full"
  const iconClassNames = "gamma:text-brand-primary-600 karwei:text-ignew-neutral-1000"

  if (url) {
    return (
      <li className="border-b border-ignew-neutral-100 first:border-t md:border-none">
        <NavLink
          end
          to={url}
          className={({ isActive }) =>
            cn(linkClassNames, isActive && "gamma:text-ignew-functional-primary-400 karwei:font-bold")
          }
        >
          {({ isActive }) => (
            <div className="flex w-full flex-row justify-between">
              <div className="flex flex-row gap-4">
                <Icon className={cn(isActive ? "gamma:text-ignew-functional-primary-400" : iconClassNames)} />
                {title}
              </div>
              <ChevronRight className="md:hidden" />
            </div>
          )}
        </NavLink>
      </li>
    )
  }

  return (
    <li>
      <DialogTrigger type="button" className={linkClassNames}>
        <div className="flex w-full flex-row justify-between">
          <div className="flex flex-row gap-4">
            <Icon className={iconClassNames} />
            {title}
          </div>
          <ChevronRight className="md:hidden" />
        </div>
      </DialogTrigger>
    </li>
  )
}
