import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"

import { useGetPersonalInformation } from "~/features/personal-information/hooks/useGetPersonalInformation"

import type { EmailUpdateRequest } from "~/services/base-api/generated"

export function useChangeEmailForm(defaultValues?: EmailUpdateRequest) {
  const { t } = useTranslation(["common", "change-email"])
  const { data } = useGetPersonalInformation()

  const form = useForm({
    defaultValues,
    values: {
      currentEmail: data?.originalEmail || "",
    } as EmailUpdateRequest,
    resolver(values, context, options) {
      return zodResolver(
        z.object({
          currentEmail: z
            .string({
              invalid_type_error: t("change-email:emailInvalid"),
              required_error: t("change-email:currentEmailRequired"),
            })
            .email(t("change-email:emailInvalid")),
          newEmail: z
            .string({
              invalid_type_error: t("change-email:emailInvalid"),
              required_error: t("change-email:newEmailRequired"),
            })
            .email(t("change-email:emailInvalid"))
            .refine((value) => value !== values.currentEmail, {
              message: t("change-email:emailsEqualError"),
            }),
        })
      )(values, context, options)
    },
    mode: "onTouched",
    resetOptions: {
      keepDirtyValues: true,
    },
  })

  return form
}
