import { useTrackLoggedIn } from "@intergamma/account"
import { isMobileApp } from "@intergamma/common/device"
import type { Formula } from "@intergamma/common/formula"
import { LoadingHeader } from "@intergamma/header"
import { Suspense } from "react"
import { Footer } from "./components/Footer"
import { ScrollToTop } from "./components/ScrollToTop"
import { useMyAccountConfig } from "./config/MyAccountConfigContext"
import { useSessionDataQuery } from "./features/session-data/hooks/useSessionDataQuery"
import { SessionDataProvider } from "./features/session-data/SessionDataContext"
import { Header } from "./layouts/Header"
import { LoadingPageLayout } from "./layouts/LoadingPageLayout"
import { Routes } from "./Routes"

export function App() {
  const { data: sessionData } = useSessionDataQuery()
  const { baseConfig } = useMyAccountConfig()

  // FIXME Get from `@intergamma/config` once it exists
  const formula = {
    id: baseConfig.fullBrand,
    baseDomain: baseConfig.baseDomain,
    brand: baseConfig.brand,
    brandName: baseConfig.brand === "karwei" ? "Karwei" : "GAMMA",
    contentfulSpaceId: "",
    contentfulToken: "",
  } satisfies Formula

  useTrackLoggedIn({
    formulaId: formula.id,
    customerId: sessionData?.uid,
    hashedId: sessionData?.hashedId,
  })

  return (
    <Suspense fallback={<LoadingHeader />}>
      <SessionDataProvider>
        <Header />
        <Suspense fallback={<LoadingPageLayout />}>
          <ScrollToTop />
          <Routes />
        </Suspense>
        {!isMobileApp && (
          <Suspense fallback={null}>
            <Footer formula={formula} />
          </Suspense>
        )}
      </SessionDataProvider>
    </Suspense>
  )
}
