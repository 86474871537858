import { isKiosk as checkIsKiosk } from "@intergamma/kiosk-util"

export enum DeviceType {
  App = "app",
  Browser = "browser",
  Kiosk = "kiosk",
}

export const getDeviceType = async (): Promise<DeviceType> => {
  if (window.navigator.userAgent.includes("IGApp")) {
    return DeviceType.App
  }

  if (await checkIsKiosk()) {
    return DeviceType.Kiosk
  }

  return DeviceType.Browser
}
