import type {
  Receipt,
  ReceiptLineItem,
  ReceiptLineItemResponse,
  ReceiptPaymentLine,
  ReceiptPaymentLineResponse,
  ReceiptResponse,
} from "../types/Receipt"
import type { ReceiptMoneyResponse } from "../types/ReceiptMoneyResponse"

import type { MoneyWithDeposit } from "~/services/types/Money"

export function mapReceiptResponse(receipt: ReceiptResponse): Receipt {
  return {
    ...receipt,
    createdOn: new Date(receipt.createdOn),
    paidOn: new Date(receipt.paidOn),
    grandTotal: {
      total: {
        amount: receipt.grandTotal.amountIncludingVat,
        currency: receipt.grandTotal.currencyCode,
      },
      vatAmount: {
        amount: receipt.grandTotal.vatAmount,
        currency: receipt.grandTotal.currencyCode,
      },
    },
    paymentLines: receipt.paymentLines.map((paymentLine) => mapReceiptPaymentLineResponse(paymentLine)),
    lineItems: receipt.lineItems.map((lineItem) => mapReceiptLineItem(lineItem)),
    vatAmounts: receipt.vatAmounts ?? [],
  }
}

function mapReceiptPaymentLineResponse(line: ReceiptPaymentLineResponse): ReceiptPaymentLine {
  return {
    ...line,
    paidOn: new Date(line.paidOn),
    amount: mapReceiptMoneyResponse(line.amount),
  }
}

function mapReceiptMoneyResponse(money: ReceiptMoneyResponse): MoneyWithDeposit {
  return {
    amount: money.amountIncludingVat,
    deposit: money.deposit,
    currency: money.currencyCode,
  }
}

function mapReceiptLineItem(lineItem: ReceiptLineItemResponse): ReceiptLineItem {
  return {
    description: lineItem.description || lineItem.productName || "",
    imageId: lineItem.imageId || "",
    isMoneyBackVoucher: lineItem.description
      ? lineItem.description.toLowerCase().includes("geldterugbon") ||
        lineItem.description.toLowerCase().includes("remboursement")
      : false,
    onlineProduct: lineItem.onlineProduct || false,
    productCode: lineItem.productCode || "",
    productDescription: lineItem.productDescription || "",
    productName: lineItem.productName || "",
    quantity: lineItem.quantity || 1,
    subtotal: mapReceiptMoneyResponse(lineItem.subtotal),
  }
}
